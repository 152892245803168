import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import img1 from './4.png'
import { baseurlvmkp } from './Baseurl';
import { Fortesresult, Roamin, tokenexistchecker } from './Workinglogic';
import Swal from 'sweetalert2';

const Wealth = () => {
    const navigate = useNavigate();
    const wealthLabels = [
        { label: 'Income', field: 'income' },
        { label: 'Savings', field: 'savings' },
        { label: 'Property', field: 'property' },
        { label: 'Inheritance', field: 'inheritance' },
        { label: 'Assets', field: 'assets' },
        { label: 'Growth', field: 'growth' },
        { label: 'Expansion', field: 'expansion' },
        { label: 'Personal Gadgets', field: 'personalGadgets' },
        { label: 'Personal Finance', field: 'personalFinance' },
        { label: 'Budgeting', field: 'budgeting' },
        { label: 'Planning', field: 'planning' },
        { label: 'Lifestyle', field: 'lifestyle' },
        { label: 'Subscription', field: 'subscription' },
        { label: 'Taxes', field: 'taxes' },
        { label: 'Housing', field: 'housing' },
        { label: 'Liabilities', field: 'liabilities' },
        { label: 'Loans', field: 'loans' },
        { label: 'Luxury & Comfort', field: 'luxuryComfort' },
        { label: 'Technology', field: 'technology' },
    
        { label: 'Goals in Wealth', field: 'goalsInWealth' },

    ];

    const [isLoading, setIsLoading] = useState(false);

    // const wealthTopics = [
    //     'assets', 'budgeting', 'expansion', 'goalsInWealth', 'growth', 'housing', 'income', 'inheritance', 'liabilities', 'lifestyle',
    //     'loans', 'luxuryComfort',  'personalFinance', 'personalGadgets', 'planning', 'property', 'savings', 'subscription', 'taxes', 'technology'
    // ];
    // const wealthTopics1 = [
    //     'assets', 'budgeting', 'expansion', 'goals In Wealth', 'growth', 'housing', 'income', 'inheritance', 'liabilities', 'lifestyle',
    //     'loans', 'luxury Comfort',  'personal Finance', 'personal Gadgets', 'planning', 'property', 'savings', 'subscription', 'taxes', 'technology'
    // ];
    const [fieldsVisibility, setFieldsVisibility] = useState( '');
    const [showErrors, setShowErrors] = useState(false);

  
    const handleChange = (rowIndex, selectIndex, value) => {
        setFieldsVisibility(prev => {
            const updatedVisibility = prev.map((row, index) => {
                if (index === rowIndex) {
                    const newSelected = [...row.selected];
                    newSelected[selectIndex] = value;

                    const nextField = (value !== "Not Applicable" && value !== 'Select' && value !== 'Working') 
                        ? Math.min(row.current + 1, 3) 
                        : selectIndex;

                    return { current: nextField, selected: newSelected };  
                }
                return row;  
            });

            return updatedVisibility;  
        });
    };
    const handleSubmit = () => {
        const allRowsFilled = fieldsVisibility.map((row, index1) => 
 
        fieldsVisibility.filter((item,index) => index == index1).map((item)=>item.selected)[0].filter((item,index3)=>index3==row.current).every((item)=>item.length!==0)


        );
        console.log(allRowsFilled.filter((item)=>item==false).length)
       
    console.log(fieldsVisibility)
         setShowErrors(!(allRowsFilled.filter((item)=>item==false).length==0?true:false));   
    
        if (allRowsFilled.filter((item)=>item==false).length==0) {
            const result = {
                name: "wealth", exam:[{
                    "id":Number(id)
                    }],
                ...Object.fromEntries(
                    wealthLabels.map((label, index) => {
                        const selectedValues = fieldsVisibility[index].selected;

                        const notApplicableIndex = selectedValues.indexOf('Not Applicable');
                        const workingIndex = selectedValues.indexOf('Working');

                        const updatedAnswers = selectedValues.map((val, i) => {
                            if ((i > notApplicableIndex && notApplicableIndex !== -1) || (i > workingIndex && workingIndex !== -1)) {
                                return "";
                            }
                            return val;
                        });

                        return [
                            label.field, {
                                name: label.field,
                                wealthQuestion: [
                                    { text: "Is this applicable?", wealthQuestionOption: [{ text: "Applicable" }, { text: "Not Applicable" }], wealthAnswer: { text: updatedAnswers[0] } ,   area: label.label},
                                    { text: "Is this working?",wealthQuestionOption: [{ text: "Working" }, { text: "Not Working" }], wealthAnswer: { text: updatedAnswers[1] } ,   area: label.label},
                                    { text: "How Critical Is This?", wealthQuestionOption: [{ text: "Not Critical" }, { text: "Somewhat Critical" }, { text: "Critical" }, { text: "Very Critical" }, { text: "Most Critical" }], wealthAnswer: { text: updatedAnswers[2] },   area: label.label },
                                    { text: "How do you see this in the future?", wealthQuestionOption: [{ text: "Necessity" }, { text: "Wish/Want/Desire" }, { text: "Dream" }, { text: "Hope" }, { text: "Miracle" }], wealthAnswer: { text: updatedAnswers[3] },   area: label.label }
                                ]
                            }
                        ];
                    })
                )
            };

            console.log(result);
            setIsLoading(true);
            axios.post(`${baseurlvmkp}/wealth/post`, result, {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            }).then(response => {
                console.log(response.status, response.data.token);
                navigate('/selfAww'); // Uncomment for navigation
            }).catch(error => {
                console.error("Error submitting form:", error);
                alert("Failed to submit the form. Please try again.");
            }).finally(() => {
                setIsLoading(false); 
              });
        } else {
            alert("Please fill out all fields for each row.");
        }
    };

    const [isScrolled, setIsScrolled] = useState(false);
    const [id,setId]=useState("")
    const handleLogout = () => {
        localStorage.removeItem('token');
       localStorage.removeItem('user');
        navigate('/');  
     };
    useEffect(() => {

        Fortesresult(navigate);
         tokenexistchecker(navigate);
           
                const user = JSON.parse(localStorage.getItem('user'));
        
                if (user && user.details && user.details.id) {
        axios.get(`${baseurlvmkp}/exam/getexampending/${user.details.id}` , {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        }).then((response) => {
            setId(response.data.id)
            axios.get(`${baseurlvmkp}/wealth/get/${(response.data.wealthid)}` , {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            }).then((response) => {
                // console.log(response.data.map((item)=>item.questions));
               
                  
                 const cleanedData = Object.fromEntries(
                    Object.entries(response.data).map(([key, { wealthQuestion, name }]) => [key, { wealthQuestion, name }])
                );
                console.log(cleanedData)
                
                const cleanedData1 = Object.fromEntries(
                    Object.entries(cleanedData).map(([key, { wealthQuestion  }]) => [key, { wealthQuestion  }])
                );
               console.log(cleanedData1)
                  
               const allQuestionsWithKeys = Object.entries(cleanedData1).flatMap(([key, value]) => 
                Array.isArray(value.wealthQuestion) ? 
                value.wealthQuestion.map(question => ({ area: key, ...question })) : []
              );
              console.log(allQuestionsWithKeys)
    
              const mappedResults = allQuestionsWithKeys.map(question => {
                const { area, wealthAnswer } = question;
                const answerText = wealthAnswer.text;
            
                return {
                    area: area,
                    Applicable: answerText === 'Not Applicable' || answerText === 'Applicable' ? answerText : "",
                    Working: answerText === 'Not Working' || answerText === 'Working' ? answerText : "",
                    Critical: ['Not Critical', 'Somewhat Critical', 'Critical', 'Very Critical', 'Most Critical'].includes(answerText) ? answerText : "",
                    Future: answerText === 'Necessity' || answerText === 'Wish/Want/Desire' || answerText === 'Dream' || 
                            answerText === 'Hope' || answerText === 'Miracle' ? answerText : ""
                };
            });
            
            const uniqueData = mappedResults.reduce((acc, item) => {
                const existing = acc.find(entry => entry.area === item.area);
                
                if (existing) {
                  existing.Applicable = existing.Applicable || item.Applicable || '';
                  existing.Working = existing.Working || item.Working || '';
                  existing.Critical = existing.Critical || item.Critical || '';
                  existing.Future = existing.Future || item.Future || '';
                } else {
                  acc.push({
                    area: item.area,
                    Applicable: item.Applicable || '',
                    Working: item.Working || '',
                    Critical: item.Critical || '',
                    Future: item.Future || '',
                  });
                }
                
                return acc;
              }, []);
              
              // Step 2: Map to the desired structure
              const data = uniqueData.map(question => {
                const applicable = question.Applicable || '';
                const working = question.Working || '';
                const critical = question.Critical || '';
                const future = question.Future || '';
              
                // Create an array for selected values based on conditions
                const selectedArray = [
                  applicable  ,
                  working ,
                  critical  ,
                  future 
                ];
              
                // Count how many selected values are not default
                const currentCount = selectedArray.filter(value => value.length !==0 &&  value.length !==0 &&  value.length !==0 &&  value.length !==0).length-1;
              
                return {
                  selected: selectedArray,
                  current: currentCount  
                };
              });
          
              setFieldsVisibility(data.length === 0 ? initializeFields() : data);
    console.log(data)
            }).catch((error)=>{
    console.log(error)
    setFieldsVisibility(initializeFields())
            })

 }).catch((error)=>{
            console.log(error)
            setFieldsVisibility(initializeFields())
                     })
                    }

        const handleScroll = () => {
            setIsScrolled(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        window.scrollTo(0, 0);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const initializeFields = () => {
     
     
         
        
 
 
        const initialFields = Array(20).fill().map((_, index) => {
            const config =  { selected: Array(4).fill(""), current: 0 };
            return { current: config.current, selected: config.selected };
        });
    
        return initialFields;
    };
    const handleClick = () => {
        Swal.fire({
            title: 'Customer Support',
            html: `
              <p><strong>WhatsApp:</strong> <a href="https://wa.me/919619604038" target="_blank">+91 9619604038</a></p>
              <p><strong>Email:</strong> <a href="https://mail.google.com/mail/?view=cm&fs=1&to=reachus@victormanickam.com" target="_blank">reachus@victormanickam.com</a></p>
            `,
            icon: 'info',
            confirmButtonText: 'Close',
          });
      };
    return (
        <div className='testwealth'>
            <div className={`display ${isScrolled ? 'blockdisplay' : 'displaynone'}`}>
                <p className='black jhk'>
                    <div className='middle'>
                        <div className='cont'>
                            <div className='row tesfier'>
                                <div className='col black'></div>
                                <div className='col black question' style={{color:'#45b43b'}}>Is this area applicable for you?</div>
                                <div className='col black question'style={{color:'#45b43b'}}>Is this area working for you?</div>
                                <div className='col black question'style={{color:'#45b43b'}}>How critical is this area for you?</div>
                                <div className='col black question'style={{color:'#45b43b'}}>How do you see your near future in this area?</div>
                            </div>
                        </div>
                    </div>
                </p>
            </div>
            <br></br>
            <p className="newtech" onClick={handleClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="43" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
          <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5"/>
        </svg>
        <span>Help</span>
      </p>
        <div className='maxcenter '  >
        <div className='rs11' style={{display:'flex',background:'white',justifyContent:'center',flexDirection:'row',gap:50}}>
        <h1 className='black fontsize '>Relationships</h1>
        <hr/>
        <h1 className='black fontsize' >Health</h1>
        <hr/>
        <h1 className='black fontsize'>Work and Education</h1>
        
        <hr/>
        <h1 className='black fontsize' style={{color:'#45B43B'}}>Wealth</h1>
        <hr/>
        <h1 className='black fontsize'>Self Awareness</h1>

        </div>
         </div>
         <br></br>
         <div className="text-end mb-3  logoutpostion">
            <span className="badge newyellow p-2" style={{background:'#45B43B'}} onClick={handleLogout}>
              <span className='logout12'>Logout</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out">
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/>
                <polyline points="16 17 21 12 16 7"/>
                <line x1="21" y1="12" x2="9" y2="12"/>
              </svg>
            </span>
          </div>
            <div className='middle'>
                <div className='cont'>
                <img src={img1} className='imglogo' style={{width:'50px'}} alt="Description of the image" />

                    <h1 className='black   tyttyt' style={{ color: '#45B43B' }}>Wealth</h1>
                    <p className='black   Personal'>This part of the Personal Assessment Test is created for you to reflect on your Wealth.</p>

                    <br />
                    <div className={`display ${isScrolled ? 'displaynone' : 'blockdisplay'}`}>
                        <div className='row'>
                            <div className='col black'></div>
                            <div className='col black question'style={{color:'#45b43b'}}>Is this area applicable for you?</div>
                            <div className='col black question'style={{color:'#45b43b'}}>Is this area working for you?</div>
                            <div className='col black question'style={{color:'#45b43b'}}>How critical is this area for you?</div>
                            <div className='col black question'style={{color:'#45b43b'}}>How do you see your near future in this area?</div>
                        </div>
                    </div>

                    {wealthLabels.map((label, index) => {
                        const currentField = fieldsVisibility[index]; // Get current field visibility

                        return (
                            <div className='row pading12' key={index}>
                                <div className='col black cap fontweight500 d-flex justify-content-center align-items-center' style={{color:'#45b43b'}}>{label.label}</div>
                                <div className='col black'>
                                    {currentField && currentField.current >= 0 && (
                                        <select
                                            className='form-select rs'
                                            value={fieldsVisibility[index].selected[0] || ""}
                                            onChange={(e) => handleChange(index, 0, e.target.value)}
                                            style={{
                                                border: '3px solid #45b43b42',                     
                                                borderColor: showErrors && fieldsVisibility[index].selected[0] === "" ? '#ff12005c' : '#45b43b42'
                                              }}                                              >
                                            <option disabled value="">--Select--</option>
                                            <option>Not Applicable</option>
                                            <option>Applicable</option>
                                        </select>
                                    )}
                                </div>
                                <div className='col black'>
                                    {currentField && currentField.current >= 1 && (
                                        <select
                                            className='form-select rs'
                                            value={fieldsVisibility[index].selected[1] || ""}
                                            onChange={(e) => handleChange(index, 1, e.target.value)}
                                            style={{
                                                border: '3px solid #45b43b42',                     
                                                borderColor: showErrors && fieldsVisibility[index].selected[1] === "" ? '#ff12005c' : '#45b43b42'
                                              }}                                              >
                                            <option disabled value="">--Select--</option>
                                            <option>Not Working</option>
                                            <option value="Working">Working</option>
                                        </select>
                                    )}
                                </div>
                                <div className='col black'>
                                    {currentField && currentField.current >= 2 && (
                                        <select
                                            className='form-select rs'
                                            value={fieldsVisibility[index].selected[2] || ""}
                                            onChange={(e) => handleChange(index, 2, e.target.value)}
                                            style={{
                                                border: '3px solid #45b43b42',                     
                                                borderColor: showErrors && fieldsVisibility[index].selected[2] === "" ? '#ff12005c' : '#45b43b42'
                                              }}                                              >
                                            <option disabled value="">--Select--</option>
                                            <option>Not Critical</option>
                                            <option>Somewhat Critical</option>
                                            <option>Critical</option>
                                            <option>Very Critical</option>
                                            <option>Most Critical</option>
                                        </select>
                                    )}
                                </div>
                                <div className='col black'>
                                    {currentField && currentField.current >= 3 && (
                                        <select
                                            className='form-select rs'
                                            value={fieldsVisibility[index].selected[3] || ""}
                                            onChange={(e) => handleChange(index, 3, e.target.value)}
                                            style={{
                                                border: '3px solid #45b43b42',                     
                                                borderColor: showErrors && fieldsVisibility[index].selected[3] === "" ? '#ff12005c' : '#45b43b42'
                                              }}                                              >
                                            <option disabled value="">--Select--</option>
                                            <option>Necessity</option>
                                            <option>Wish/Want/Desire</option>
                                            <option>Dream</option>
                                            <option>Hope</option>
                                            <option>Miracle</option>
                                        </select>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                    <br />
                    <div className='row fotbuttons'>
                         <button className='btn btn-primary bigger' onClick={()=>{navigate('/we')}}>Back</button>
                            {/* <button className='btn btn-primary bigger' style={{backgroundColor: '#45B43B'}} onClick={handleSubmit}>Next</button> */}
                            {isLoading?  <button className='btn btn-primary bigger' style={{background:'white'}}  > 
                            <svg viewBox="25 25 50 50" class="spinner-container" style={{width:'21px'}}>
  <circle cx="50" cy="50" r="20" class="spinner-loader"></circle>
</svg>

                            </button>:<button className='btn btn-primary bigger'  onClick={handleSubmit}> 
                      Next

                            </button>}
                        
                    </div>

                    <br></br>
                    <div className='row'>
                        <div className='col'>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Wealth;
