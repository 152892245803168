import React from 'react'
import { FaLaptop } from 'react-icons/fa'
import { PiDesktopTowerBold } from 'react-icons/pi'

const Responsive = () => {
  return (
    <div className='fullwiped' style={{ height: '100vh' }}>
      <div className="home-container"  >
        <div className="thankyou-box thankyou" data-aos="fade-up" style={{ padding: '30px', borderRadius: '8px', boxShadow: '0 4px 20px rgba(0,0,0,0.1)', textAlign: 'center', height: '460px', width: '360px' }}>
          <div className='imgarea'>

            <img
              className='img1'
              src='https://static.wixstatic.com/media/5715af_a286bfb446374a96877a4193a1ef0933~mv2.png/v1/fill/w_114,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PAT%20Logo.png'
              alt="Logo"
              style={{ width: '30%', marginBottom: '15px' }}
            />
            <img
              src='https://static.wixstatic.com/media/77a25b_ec2fa92086954799bf72699afde7a666~mv2.png/v1/fill/w_191,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/VMKGBLUE.png'
              alt="Logo"
              style={{ width: '60%', marginBottom: '15px' }}
            />
          </div>
          <h1 style={{ color: '#396AA9', margin: ' ',  }}><PiDesktopTowerBold style={{height:'50px',width:'50px'}}  />

          </h1>

          <h2 style={{ color: '#396AA9',fontSize:'17px', margin: '28px 0' }}> Switch to Desktop or Laptop for the Best Experience!</h2>
          <p style={{ color: '#333', margin: '5px 0',fontWeight:'500',color:'grey',fontSize:'13px' }}>
            We’re excited for you to use our platform, but it’s currently designed for desktop or laptop use. To access this page and enjoy a smooth experience, please open it on a desktop or laptop.
          </p>
          <br></br>
          <p style={{ color: '#333', margin: '5px 0',fontStyle:'italic',color:'black',fontSize:'12px' }}>Thank you for understanding, and we can’t wait to see you there!</p>


        </div>
      </div>
    </div>

  )
}

export default Responsive