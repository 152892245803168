import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import bgImgae from "../images.png";
import axios from 'axios';
import { baseurlvmkp } from '../Files/Subjects/Baseurl';
import ModalComponent from './ModalComponent';
import { Button } from 'react-bootstrap';
import Modalc2 from './Modalc2';
import Pagetestfordashboard from './Pagetestfordashboard';
 
const Coustomerdashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState([])
const [usersData, setUsersData] = useState([])
const [examidfind, setExamidfind] = useState('')
const [status, setStatus] = useState([])
useEffect(() => {

  const userData = JSON.parse(localStorage.getItem("user"));
  console.log(userData.details.id)
console.log( localStorage.getItem('user'))
  axios.delete(`${baseurlvmkp}/exam/delete`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }).then((deleteResponse) => {
     console.log('Exam deleted successfully', deleteResponse);

   
}).catch((deleteError) => {
    console.error('Error deleting exam:', deleteError);
});

axios.get(`${baseurlvmkp}/exam/dashexamuser/${userData.details.id}`, {
  headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
}).then((response) => {
  // Map the response data to change key names
  const modifiedData = response.data.map(user => ({
    id: user.id,           // Change 'id' to 'myId'
    status: user.status,   // Change 'status' to 'myStatus'
    age: user.age,         // Change 'age' to 'myAge'
    myGender: user.gender,   // Change 'gender' to 'myGender'
    date: formatDate(user.end_date==null?user.start_date:user.end_date) // Change 'end_date' to 'myEndDate'
  }));
  
  setUsersData(modifiedData);
  console.log(modifiedData); // Log the modified data
}).catch((error) => {
  console.error("Error fetching data:", error);
});




axios.get(`${baseurlvmkp}/exam/userstatus/${userData.details.id}`, {
  headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
}).then((deleteResponse) => {
   console.log(  deleteResponse.data[0].status);
   setStatus(deleteResponse.data[0].status);
 
}).catch((deleteError) => {
  console.error('Error deleting exam:', deleteError);
});




  axios.get(`${baseurlvmkp}/vmkp/get/${userData.details.id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }).then((response) => {
    console.log(response.data);  
    setUsername(response.data);  
  })
  .catch((error) => {
    console.error("Error fetching data:", error);
  });


}, [ isModalOpen])
const token =localStorage.getItem('user');
let id  =JSON.parse(token).details.id;


const formatDate = (dateString) => {
  const date = new Date(dateString); // Create a Date object from the string
  const day = String(date.getDate()).padStart(2, '0'); // Get the day with leading zero
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-based index)
  const year = date.getFullYear(); // Get the year

  return `${day}-${month}-${year}`; // Return formatted date
};

 
  const handleLogout = () => {
     localStorage.removeItem('token');
    localStorage.removeItem('user');
     navigate('/');  
  };
 

const openModal = () =>{ setIsModalOpen(true);} // Function to open the modal
const closeModal = () => setIsModalOpen(false); // Function to close the modal
// const comehelper1='https://wa.me/919999999999'

const handleloadingcomplete=()=>{
  console.log("handleloadingcomplete")
}
const handledownloadbutton=(data)=>{
  console.log(data)
}
const childRef = useRef();

// Function to call the child component's method
const callChildFunction = (id2) => {
  if (childRef.current) {
    childRef.current.alertMessage(id2); 
  }
};
const [setstatusmarks, setSetstatusmarks] = useState([])
const handleChildData = (data) => {
   console.log("Received from child:", data);
  setSetstatusmarks(data)
};
const [blockid, setBlockid] = useState([])
const [blockid2, setBlockid2] = useState([])
const handletruefalse=(data,id2)=>{
  setBlockid(data)
  setBlockid2(id2)
  
  console.log(data)
}

const handlecontinueexam=()=>{
navigate('/redirectpage')
}
const handlestartexam=()=>{
  navigate('/payment')

}
const handleClick = () => {
  Swal.fire({
      title: 'Customer Support',
      html: `
        <p><strong>WhatsApp:</strong> <a href="https://wa.me/919619604038" target="_blank">+91 9619604038</a></p>
        <p><strong>Email:</strong> <a href="https://mail.google.com/mail/?view=cm&fs=1&to=reachus@victormanickam.com" target="_blank">reachus@victormanickam.com</a></p>
      `,
      icon: 'info',
      confirmButtonText: 'Close',
    });
};
  return (
    <> 
<Modalc2
  isModalOpen={isModalOpen}
  closeModal={() => setIsModalOpen(false)}
/>
<div className='fullwiped'>
        <div className="home-container">
          <section className="content ttt  backimgchange" style={{ width:'122%',height:'99%'}}>
            <div className="left-side  backimgchange">
              {/* <h1 style={{color:'black'}}>{setstatusmarks}</h1> */}
              <div className='imgback' style={{ padding: '12px', zIndex: '9999',color:'black', position: 'relative',textAlign:'left' }} >
                <img
                  src="https://static.wixstatic.com/media/5715af_a286bfb446374a96877a4193a1ef0933~mv2.png/v1/fill/w_114,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PAT%20Logo.png"
                  alt="PAT Logo"
                  className="banner"
                />
                <img
                  src="https://static.wixstatic.com/media/77a25b_ec2fa92086954799bf72699afde7a666~mv2.png/v1/fill/w_191,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/VMKGBLUE.png"
                  alt="VMK Blue Banner"
                  className="banner"
                />
              </div>
              {/* Updated video iframe */}
      <div className='cardforming' style={{color:'black'}}>
 <div className='card cardtier' style={{background:'#fffff'}}>
    <div className="card-body">
        <div className='row'>
          <div className='col'>
          <div className='profileletter'>
  {username?.first_name?.[0] || ''}
</div>
<div className='row namer'>
    <div className='col'>
   <div className='formingcenter' style={{textTransform:'capitalize', paddingBottom:'7px' }}>{username.first_name}&nbsp;{username.last_name} </div>
 {/* {username.first_name}&nbsp;{username.last_name} */}
   <span className='editach formingcenter' onClick={openModal}>Edit</span>

    </div>
</div>
{/* <div style={{lineHeight:'46px'}}>

     <div className='flexing2'>
    <div>Birth Date:14/02/2002</div>
    <div>Gender:Male</div>
 </div>
 <div className='flexing2'>
    <div>Marital Status:Single</div>
    <div>occupation:Worker</div>
 </div>

 <div className='flexing2'>
    <div>phone_no:0987654321</div>
    <div>country:India</div>
 </div>
 <div className='flexing2'>
    <div>State:Maharastra</div>
    <div>City:Ambernath</div>
 </div>
</div> */}

<div>
  {status==true?  <div className='svgdesgin'>
      <p className='italicfont500'>When you're ready, click the "Start Exam" button below to begin. Best of luck!</p>

                            <button className="custom-button" onClick={()=>handlestartexam()}>
                              <span className="custom-button-text">Start Exam</span>
                              <div className="custom-fill-container"></div>
                            </button>

      </div>: <div className='svgdesgin'>
      <p className='italicfont500'>Ready to test your knowledge? Click the "Continue Exam" button below to pick up where you left off. Good luck!
      </p>

                            <button className="custom-button" onClick={()=>handlecontinueexam()}>
                              <span className="custom-button-text">Continue Exam</span>
                              <div className="custom-fill-container"></div>
                            </button>

      </div>
      }
 
</div>
          </div>
        </div>
    </div>
 </div>
       </div>
            </div>
        
    <div
      className="right-side nonerighttop nonelefttop scrollhidden"
      id="rightangle"
      style={{
        color: 'black',
        background: 'white',
        height: '100%',
        overflowY: 'auto',
        alignItems:'end'
      }}
    >
      <div className="card">
        <div className="card-body">
          <div className="text-end mb-3 flexring">
          <p className="newtechone" onClick={handleClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="43" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
          <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5"/>
        </svg>
        <span>Help</span>
      </p>
            <span className="badge newblue p-2 logoin" onClick={handleLogout}>
              <span className='logout12'>Logout</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out">
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/>
                <polyline points="16 17 21 12 16 7"/>
                <line x1="21" y1="12" x2="9" y2="12"/>
              </svg>
            </span>
          </div>
        <div className='card'>
          <div className='card-body'>
       
<div className="row" style={{ gap: '9px' }}>
  {usersData.length > 0 ? (
    <>
      {usersData.map((user) => (
        <div className="col bwhite" key={user.id}>
          <div className="testering">
            <div className="card-container">
              <h5 className="fw-bold mb-2">{user.date}</h5>
              <p className="mb-1">Age: {user.age}</p>
              <p className="mb-2">
                Status:{" "}
                <span
                  className={`${
                    user.status === 'Completed'
                      ? 'green'
                      : user.status === 'Pending' || user.status === 'pending'
                      ? 'orange'
                      : 'bg-danger'
                  }`}
                >
                  {user.status}
                </span>
              </p>
              {user.status === 'Completed' ? (
                blockid === false || blockid2 !== user.id ? (
                  <button
                    className="setter"
                    style={{ fontSize: '12px', padding: '9px' }}
                    onClick={() => {
                      setExamidfind(user.id);
                      callChildFunction(user.id);
                    }}
                  >
                    <i className="bi bi-download"></i>Result{" "}
                    <span
                      className="badge bg-success"
                      style={{ fontWeight: '500' }}
                    >
                      Download
                    </span>
                  </button>
                ) : (
                  <p className="setter" style={{ fontSize: '12px' }}>
                    <i className="bi bi-download"></i>Result:{" "}
                    <span
                      className="badge bg-success"
                      style={{ fontWeight: '500' }}
                    >
                      Downloading {setstatusmarks}%...
                    </span>
                  </p>
                )
              ) : user.status === 'pending' ? (
                <>
                  <p
                    className="setter"
                    style={{ fontSize: '12px' }}
                    onClick={() => handlecontinueexam()}
                  >
                    <i className="bi bi-download"></i>Result:{" "}
                    <span
                      className="badge text-warning"
                      style={{ fontWeight: '500', fontSize: '15px' }}
                    >
                      Pending
                    </span>
                  </p>
                </>
              ) : null}
            </div>
          </div>
        </div>
      ))}
      {usersData.length % 2 !== 0 && (
        <div className="col bwhite">
          {/* Add this extra column */}
        </div>
      )}
    </>
  ) : (
    <div className="col-12 text-center">
      <div className="existresult">
        <p className="fw-bold">No result of this User</p>
        <div className="loader">
          <div className="loader__bar"></div>
          <div className="loader__bar"></div>
          <div className="loader__bar"></div>
          <div className="loader__bar"></div>
          <div className="loader__bar"></div>
          <div className="loader__ball"></div>
        </div>
      </div>
    </div>
  )}
</div>


          </div>
        </div>
        </div>
      </div>
    </div>

          </section>
        </div>
</div>
{/* <h1 style={{color:'black'}}>{blockid[0]}</h1> */}
<Pagetestfordashboard ref={childRef} Userreportid={id} loadingstatus={handletruefalse} sendDataToParent={handleChildData}  comehelper={examidfind}  />
    </>
    
  );
};

export default Coustomerdashboard;
