import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import { baseurlvmkp } from './Subjects/Baseurl';

const Payment = () => {
  const [promoCode, setPromoCode] = useState('');
  const [discount, setDiscount] = useState(0);
  const [subtotal, setSubtotal] = useState(500); // Using useState for subtotal
  const [couponApplied, setCouponApplied] = useState(false); // State to track coupon application status
  const navigate = useNavigate(); // Initialize useNavigate
  const [total, setTotal] = useState(500)
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [amount, setAmount] = useState(500); 
  const [userdata, setUserdata] = useState([])
  const [loading, setLoading] = useState(false); // Loading state

  // Initialize AOS
  const phone_no=userdata.phone_no
  console.log(phone_no)

const userstatus=(id)=>{
  axios.get(`${baseurlvmkp}/exam/userstatus/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }).then((deleteResponse) => {
     console.log(  deleteResponse.data[0].status);
    //  return(deleteResponse.data[0].status);
   if(deleteResponse.data[0].status){
    navigate('/Totorialstartpage')
   }
   if(deleteResponse.data[0].status==false){
    navigate('/redirectpage')
   }
  }).catch((deleteError) => {
    console.error('Error deleting exam:', deleteError);
  });
  
}

  useEffect(() => {

    axios.get(`${baseurlvmkp}/myorder/get/${JSON.parse(localStorage.getItem('user')).details.id}`) 
    .then((response) => {
       console.log(response.data.length)
  console.log(response.data.filter((item)=>item.examStatus=='false'))

if( response.data.length!==0){
  response.data.filter((item)=>item.examStatus=='false'&& item.status=='paid'?userstatus(JSON.parse(localStorage.getItem('user')).details.id):true)
  response.data.filter((item)=>item.examStatus=='false'&& item.status=='couponpayment'?userstatus(JSON.parse(localStorage.getItem('user')).details.id):true)

}else{

}

    //   setPaymentpage(response.data.filter((item) => {
    //     return item.examStatus === 'true' || (item.examStatus !== 'false' && item.examStatus !== 'true');
    // }));
        })
    .catch((err) => {
      console.log(err.message); 
    })
    .finally(() => {
      console.log('finally');
    });


    const token = localStorage.getItem('user');
    axios.get(`${baseurlvmkp}/vmkp/get/${JSON.parse(token).details.id}`)
    .then(response => {
      console.log(response.data);
      setUserdata(response.data)
    })
    .catch(error => {
      console.error(error);
    });



    AOS.init({
      duration: 1000, // Duration of the animation in milliseconds
    });
  }, []);

  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
  };
const coupen=()=>{
  setIsInputDisabled(!isInputDisabled)
}
  const applyPromoCode = async () => {
    if (couponApplied) return; // Prevent applying a coupon if already applied

    const token = localStorage.getItem('user');
    const customerId = JSON.parse(token).details.id;
    const couponCode = promoCode;

    try {
      const response = await axios.post(
        `${baseurlvmkp}/coupen/post/apply?customer=${customerId}&coupen=${couponCode}`
      );

      if (response.status === 200) {
       
     console.log(response.data)
     if(response.data=='Coupen is Not Valid'){
alert("Invalid Coupon")
     }
     else if(response.data=='Usage limit exceeded.'){
      alert("Usage limit exceeded.")

     }else if(response.data=="Coupen Is Expired"){
      alert("Coupon Is Expired")

     }else if(response.data=="Coupon already used."){
      alert("Coupon already used.")

     }
     else{
      console.log(response.data[0])
console.log( response.data.slice(1))
      console.log('Coupon applied successfully!', response.data[2]);
      getCouponById(response.data.slice(1)); // Pass couponId to getCouponById function
      setCouponApplied(true); // Set coupon applied status to true
     }
     
     
     
      } else {
        console.log('Failed to apply coupon');
      }
    } catch (error) {
      console.error('Error applying coupon:', error);
    }
  };

  const getCouponById = async (couponId) => {
    try {
      const response = await axios.get(`${baseurlvmkp}/coupen/get/${couponId}`);

     
      if (response.status == 200) {
        console.log('Coupon details:', response.data);
    
        const percentage = response.data.percentage; // Extract percentage
        console.log(percentage)
        const newDiscount = (subtotal * percentage) / 100; // Calculate discount
        const newTotal = subtotal - newDiscount; // Calculate new total after discount
    
        console.log(`Percentage: ${percentage}%, Discount: ${newDiscount}, Subtotal: ${subtotal}`);
        setAmount(newTotal)
        setDiscount(newDiscount); // Update discount state
        setSubtotal(subtotal); // Retain original subtotal if required
        setTotal(newTotal); // Update total state
    }
    
      else {
        console.log('Failed to fetch coupon details');
      }
    } catch (error) {
      console.error('Error fetching coupon:', error);
    }
  };

  const delivery = 0; // Free delivery
  // const total = subtotal - discount;
  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };
//   const handlePayment = async () => {
 
// if(amount!==0 ){    
//     if (!amount || isNaN(amount)) {
//       Swal.fire({
//         icon: 'error',
//         title: 'Invalid Amount',
//         text: 'Please enter a valid amount.',
//       });
//       return;
//     }
  
//     // Confirm payment action
//     Swal.fire({
//       title: 'Proceed to Checkout?',
//       text: 'Do you want to proceed with the payment?',
//       icon: 'question',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Yes, proceed!',
//       cancelButtonText: 'No, cancel',
//     }).then(async (result) => {
//       if (result.isConfirmed) {
//         // Load Razorpay script
//         const isScriptLoaded = await loadRazorpayScript();
//         if (!isScriptLoaded) {
//           Swal.fire({
//             icon: 'error',
//             title: 'Script Load Failed',
//             text: 'Failed to load Razorpay SDK. Please check your connection.',
//           });
//           return;
//         }
  
//         try {
//           const token = localStorage.getItem('user');
  
//           // Create order on the backend
//           const { data: order } = await axios.post(`${baseurlvmkp}/vmkp/createorder`, {
//             amount: amount, // Convert to smallest currency unit (paise)
//             customer: { id: JSON.parse(token).details.id },
//           });
  
//           // Calculate price breakdown
//           const subtotal = amount * 100; // Subtotal in paise
//           const tax = subtotal * 0.18; // 18% tax
//           const discount = subtotal * 0.1; // 10% discount
//           const totalAmount = subtotal + tax - discount;
  
//           const options = {
//             key: "rzp_test_tZrAScctm2CFkZ", // Replace with your Razorpay Key ID
//             amount: totalAmount, // Total amount in smallest currency unit
//             currency: "INR",
//             name: "VMKG",
//             description: "Payment Transaction",
//             image: "https://static.wixstatic.com/media/77a25b_ec2fa92086954799bf72699afde7a666~mv2.png/v1/fill/w_191,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/VMKGBLUE.png", // Optional logo
//             order_id: order.id, // Pass the order ID from the backend
//             handler: async function (response) {
//               try {
//                 // Send the payment success response to the backend for verification
//                 await axios.post(`${baseurlvmkp}/vmkp/createpayment`, {
//                   orderId: order.id,
//                   status: 'paid',
//                   payment_id: response.razorpay_payment_id,
//                 });
  
//                 Swal.fire({
//                   icon: 'success',
//                   title: 'Payment Successful',
//                   text: 'Thank you for your payment!',
//                 }).then(() => {
//                   navigate('/Thankyou');
//                 });
//               } catch (error) {
//                 console.error("Payment Success Callback Error:", error);
//                 Swal.fire({
//                   icon: 'error',
//                   title: 'Payment Verification Failed',
//                   text: 'Something went wrong during payment verification.',
//                 });
//               }
//             },
//             prefill: {
//               name: "Arjun",
//               email:userdata.email,
//               contact:userdata.phone_no,
//             },
//             notes: {
//               subtotal: `₹${(subtotal / 100).toFixed(2)}`,
//               tax: `₹${(tax / 100).toFixed(2)}`,
//               discount: `-₹${(discount / 100).toFixed(2)}`,
//               total: `₹${(totalAmount / 100).toFixed(2)}`,
//             },
//             theme: {
//               color: "#3E73D2",
//             },
//           };
  
//           const rzp = new window.Razorpay(options);
//           rzp.open();
//         } catch (error) {
//           console.error("Error creating Razorpay order:", error);
//           Swal.fire({
//             icon: 'error',
//             title: 'Payment Failed',
//             text: 'Payment initiation failed. Please try again.',
//           });
//         }
//       }
//     });
//   }else{
//     // Totorialstartpage
//     const token = localStorage.getItem('user');

//     axios.post(`${baseurlvmkp}/vmkp/createorder`, {
//       amount: amount,
//       customer: { id: JSON.parse(token).details.id },
//     })
//       .then(response => {
//         navigate('/Totorialstartpage')

//         console.log("Order created:", response.data);
//         return response.data;
//       })
//       .catch(error => {
//         console.log("Error creating order:", error.message);
//       });
//   }


//   };
  
  // const handlePayment = async () => {

  //   // Swal.fire({
  //   //   title: 'Proceeding to Checkout',
  //   //   text: 'Are you sure you want to proceed?',
  //   //   icon: 'info',
  //   //   showCancelButton: true,
  //   //   confirmButtonColor: '#3085d6',
  //   //   cancelButtonColor: '#d33',
  //   //   confirmButtonText: 'Yes, proceed!',
  //   //   cancelButtonText: 'No, cancel',
  //   // }).then((result) => {
  //   //   if (result.isConfirmed) {
  //   //     // Handle checkout logic here
       
  //   //   }
  //   // });
  //   if (!amount || isNaN(amount)) {
  //     alert("Please enter a valid amount.");
  //     return;
  //   }

  //   const isScriptLoaded = await loadRazorpayScript();
  //   if (!isScriptLoaded) {
  //     alert("Failed to load Razorpay SDK. Please check your connection.");
  //     return;
  //   }

  //   try {
  //     const token = localStorage.getItem('user');

  //       // Create order on the backend
  //       const { data: order } = await axios.post(`${baseurlvmkp}/vmkp/createorder`, {
  //         amount: amount , // Convert to smallest currency unit (paise)
  //         customer:{id:JSON.parse(token).details.id}
  //       });
      
  //       // Calculate price breakdown
  //       const subtotal = amount * 100; // Subtotal in paise
  //       const tax = subtotal * 0.18;  // 18% tax
  //       const discount = subtotal * 0.1; // 10% discount
  //       const totalAmount = subtotal + tax - discount;
      
  //       const options = {
  //         key: "rzp_test_tZrAScctm2CFkZ", // Replace with your Razorpay Key ID
  //         amount: totalAmount, // Total amount in smallest currency unit
  //         currency: "INR",
  //         name: "Your Company",
  //         description: "Payment Transaction",
  //         image: "https://your-logo-url.com/logo.png", // Optional logo
  //         order_id: order.id, // Pass the order ID from the backend
  //         handler: async function (response) {
  //           // Log both the payment response and the order details
  //           console.log("Payment Success Response:", response);
  //       // 
      
  //           try {
  //             // Send the payment success response to the backend for verification
  //             await axios.post(`${baseurlvmkp}/vmkp/createpayment`, {
  //               // ...response,
  //               orderId: order.id,
  //               status:'paid',
  //               payment_id:response.razorpay_payment_id

  //             });
  //             navigate('/Thankyou');
  //             // alert("Payment Successful!");
  //           } catch (error) {
  //             console.error("Payment Success Callback Error:", error);
  //             alert("Payment verification failed.");
  //           }
  //         },
  //         prefill: {
  //           name: "Customer Name",
  //           email: "customer@example.com",
  //           contact: "9999999999",
  //         },
  //         notes: {
  //           subtotal: `₹${(subtotal / 100).toFixed(2)}`,
  //           tax: `₹${(tax / 100).toFixed(2)}`,
  //           discount: `-₹${(discount / 100).toFixed(2)}`,
  //           total: `₹${(totalAmount / 100).toFixed(2)}`,
  //         },
  //         theme: {
  //           color: "#3399cc",
  //         },
  //       };
      
  //       const rzp = new window.Razorpay(options);
  //       rzp.open();
  //     } catch (error) {
  //       console.error("Error creating Razorpay order:", error);
  //       alert("Payment initiation failed. Please try again.");
  //     }
      
  // };
  const handleCheckout = () => {



    Swal.fire({
      title: 'Proceeding to Checkout',
      text: 'Are you sure you want to proceed?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, proceed!',
      cancelButtonText: 'No, cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        // Handle checkout logic here
        navigate('/Thankyou');
      }
    });
  };
  const handlePayment = async () => {
    if (amount !== 0) {
      if (!amount || isNaN(amount)) {
        Swal.fire({
          icon: "error",
          title: "Invalid Amount",
          text: "Please enter a valid amount.",
        });
        return;
      }

      Swal.fire({
        title: "Proceed to Checkout?",
        text: "Do you want to proceed with the payment?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, proceed!",
        cancelButtonText: "No, cancel",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true); // Stop loading

          // Load Razorpay script
          const isScriptLoaded = await loadRazorpayScript();
          if (!isScriptLoaded) {
            Swal.fire({
              icon: "error",
              title: "Script Load Failed",
              text: "Failed to load Razorpay SDK. Please check your connection.",
            });
            setLoading(false); // Stop loading
            return;
          }

          try {
            const token = localStorage.getItem("user");
        
            // Create order on the backend
            const { data: order } = await axios.post(`${baseurlvmkp}/vmkp/createorder`, {
              amount: amount, // Convert to smallest currency unit
              customer: { id: JSON.parse(token).details.id },
            });

            // Calculate price breakdown
            const subtotal = amount * 100;
            const tax = subtotal * 0.18;
            const discount = subtotal * 0.1;
            const totalAmount = subtotal + tax - discount;

            const options = {
              key: "rzp_live_CkXLceXj8s6W9g",
              amount: totalAmount,
              currency: "INR",
              name: "VMKG",
              description: "Payment Transaction",
              image: "YOUR_LOGO_URL",
              order_id: order.id,
              handler: async function (response) {
                try {
                  setLoading(true); // Start loading
                  await axios.post(`${baseurlvmkp}/vmkp/createpayment`, {
                    orderId: order.id,
                    status: "paid",
                    payment_id: response.razorpay_payment_id,
                  });

                  Swal.fire({
                    icon: "success",
                    title: "Payment Successful",
                    text: "Thank you for your payment!",
                  }).then(() => {
                    setLoading(false); 
                    navigate("/Thankyou");
                  });
                } catch (error) {
                  setLoading(false); 
                  console.error("Payment Success Callback Error:", error);
                  Swal.fire({
                    icon: "error",
                    title: "Payment Verification Failed",
                    text: "Something went wrong during payment verification.",
                  });
                }
              },
              prefill: {
                name: "Arjun",
                email: userdata.email,
                contact: userdata.phone_no,
              },
              notes: {
                subtotal: `₹${(subtotal / 100).toFixed(2)}`,
                tax: `₹${(tax / 100).toFixed(2)}`,
                discount: `-₹${(discount / 100).toFixed(2)}`,
                total: `₹${(totalAmount / 100).toFixed(2)}`,
              },
              theme: { color: "#3E73D2" },
            };

            const rzp = new window.Razorpay(options);
            rzp.open();
          } catch (error) {
            console.error("Error creating Razorpay order:", error);
            setLoading(false); 
            Swal.fire({
              icon: "error",
              title: "Payment Failed",
              text: "Payment initiation failed. Please try again.",
            });
          } finally {
            setLoading(false); // Stop loading
          }
        }
      });
    } else {
      const token = localStorage.getItem("user");
      setLoading(true); // Start loading
      axios
        .post(`${baseurlvmkp}/vmkp/createorder`, {
          amount: amount,
          customer: { id: JSON.parse(token).details.id },
        })
        .then((response) => {
          navigate("/Totorialstartpage");
          console.log("Order created:", response.data);
        })
        .catch((error) => {
          console.log("Error creating order:", error.message);
        })
        .finally(() => setLoading(false)); // Stop loading
    }
  };
  return (
    <>
      <nav className='makering12' style={{boxShadow:'none', borderBottom: '1px solid #00000029',display:'flex',
  zIndex: 9999,
  position: 'relative',}}>
        <img
          src="https://static.wixstatic.com/media/5715af_a286bfb446374a96877a4193a1ef0933~mv2.png/v1/fill/w_114,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PAT%20Logo.png"
          alt="PAT Logo"
          className="banner"
          data-aos="fade-down"
        />
        {/* <img
          src="https://static.wixstatic.com/media/77a25b_ec2fa92086954799bf72699afde7a666~mv2.png/v1/fill/w_191,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/VMKGBLUE.png"
          alt="VMK Blue Banner"
          className="banner"
          data-aos="fade-up"
        /> */}
              <h1 className="cart-title" style={{position:'relative',top:'34px'}}>Checkout</h1>

      </nav>
      <br />
      <div className="payment-container" style={{ boxShadow: 'none',position:'relative',top:'-41px',maxWidth:'1000px' }}>
  <div className="payment-content" style={{ display: 'flex', flexWrap: 'wrap' }}>
    {/* Left Section */}
    <div className="cart-section" data-aos="fade-right" style={{ flex: '1', padding: '20px'}}>
      {/* <h1 className="cart-title">User and Product Details</h1> */}
      <div className="product-section">
        <h1 className="new-title">User</h1>
        <div className='product-section-flex'>
        <div className="profile " data-ao s="fade-left">
          {/* <img
            src="https://static.wixstatic.com/media/77a25b_5348da0107514037b3e1e83878dc2ac3~mv2.png/v1/fill/w_100,h_43,al_c,q_90,usm_0.66_1.00_0.01/77a25b_5348da0107514037b3e1e83878dc2ac3~mv2.webp"
            alt="Personal Assessment Test Report"
          /> */}
<span style={{textTransform:'uppercase'}}>{userdata?.first_name ? userdata.first_name[0] : ''}</span>
</div>
        <div className="product-details" data-aos="fade-up">
          <p className='fontsize'><strong>Name:</strong>{userdata.first_name} {userdata.last_name}</p>
          <p className='fontsize'><strong>Gender:</strong> {userdata.gender}</p>
          <p className='fontsize'><strong>Age:</strong>{userdata.age}</p>
        </div>
        </div>
      </div>
<br></br>
       <div className="product-section">
        <h1 className="new-title">Product</h1>
        <div className='product-section-flex'>
        <div className="product-image" data-aos="fade-left">
          <img
            src="https://static.wixstatic.com/media/77a25b_5348da0107514037b3e1e83878dc2ac3~mv2.png/v1/fill/w_100,h_43,al_c,q_90,usm_0.66_1.00_0.01/77a25b_5348da0107514037b3e1e83878dc2ac3~mv2.webp"
            alt="Personal Assessment Test Report"
          />
        </div>
        <div className="product-details" data-aos="fade-up">
          <p className='fontsize'><strong>Item:</strong> Personal Assessment Test</p>
          <p className='fontsize'><strong>Validity:</strong> 24 Hours</p>
          <p className='fontsize'><strong>Price:</strong> ₹{subtotal.toFixed(2)}</p>
        </div>
        </div>
      </div>
    </div>

    {/* Right Section */}
    <div
      className="order-summary-section"
      data-aos="fade-left"
      style={{
        flex: '1',
        padding: '20px',
        backgroundColor: '#F7F7F9', // Light grey background
        height:'130%',
        height: '85vh'
        // borderLeft: '1px solid #dcdcdc', // Border for clear division
      }}
    >
      <div className=" ">
        <div className="order-details" data-aos="fade-up">
<div className='dotted'>
<strong className='maxblack'>Order Summary</strong>

</div>

<div className='marginbottom'>
<p className='flexbetween'><strong>Personal Assessment Test</strong>&nbsp;<strong>500.00</strong> </p>
<p className='flexbetween'><strong>Validity: 24 Hours </strong>&nbsp;  </p>
  </div>
   <br></br>
   <div className=' ' style={{    marginTop: '-22px!important',borderBottom:'1px dotted  rgba(0, 0, 0, 0.377)'}}>
<p className='flexbetween' onClick={()=>coupen()}><strong className='code' >Add Coupon Code</strong>&nbsp;<strong> </strong> </p>
<div className=" promo-code " data-aos="fade-up">
      
         {1==1?<> <input
            type="text"
            placeholder="Enter a promo code"
            value={promoCode}
            onChange={handlePromoCodeChange}
            disabled={couponApplied} 
          />
          <button onClick={applyPromoCode} disabled={couponApplied}>Apply</button></>:"" }
        </div>
 
          <p className='flexbetween'><strong>Promo Code:</strong>&nbsp; {promoCode}</p>
          {couponApplied && (
            <p style={{ color: 'green', fontWeight: 'bold' }}>Coupon Applied Successfully</p>
          )}
          <p className="discount flexbetween"><strong>Discount:</strong>&nbsp;<span style={{ color: '#44B33A', fontWeight: '600' }}> -₹{discount.toFixed(2)}</span></p>
          <p className="total flexbetween"><strong>Total:</strong>&nbsp; ₹{total.toFixed(2)}</p>
         <div style={{display:'flex',justifyContent:'center'}}> <img 
            src='https://static.wixstatic.com/media/77a25b_1d08d845574b4541a951c8f311e1ade6~mv2.png/v1/fill/w_291,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/payment_long.png' 
            alt="Payment Success" 
            style={{ margin: '15px 0', width: '80%' }} 
          /></div>
  </div>
  <div>


    </div>
          {/* <p><strong>Subtotal:</strong>&nbsp; ₹{subtotal.toFixed(2)}</p>
          <p><strong>Promo Code:</strong>&nbsp; {promoCode}</p>
          {couponApplied && (
            <p style={{ color: 'green', fontWeight: 'bold' }}>Coupon Applied Successfully</p>
          )}
          <p className="discount"><strong>Discount:</strong>&nbsp; -₹<span style={{ color: '#44B33A', fontWeight: '600' }}>{discount.toFixed(2)}</span></p>
          <p className="total"><strong>Total:</strong>&nbsp; ₹{total.toFixed(2)}</p> */}
        </div>
          {/* promo-code */}
        {/* <div className="" data-aos="fade-up">
      
          <input
            type="text"
            placeholder="Enter a promo code"
            value={promoCode}
            onChange={handlePromoCodeChange}
            disabled={couponApplied} 
          />
          <button onClick={applyPromoCode} disabled={couponApplied}>Apply</button> 
        </div> */}
        {/* checkout */}

        <p className="total flexbetween" style={{justifyContent:'center',color:'rgb(101 101 101)',marginTop:'35px',fontSize:'21px',fontWeight:500}}><strong>Total:</strong>&nbsp; ₹{total.toFixed(2)}</p>

        <div className="checkout">
      <button onClick={handlePayment} disabled={loading}>
        {loading ? "Processing..." : "Checkout"}
      </button>
      {loading && <div className="spinner">Loading...</div>}

      
    </div>
      </div>
    </div>
  </div>
</div>

    </>
    
  
    
   
  );
};

export default Payment;
