import React, { useState, useEffect, useRef } from 'react';
import transparent from './../src/Pagesmaker/transparet.png';
import axios from 'axios';
import { baseurlvmkp } from './Files/Subjects/Baseurl';
import { useParams } from 'react-router-dom';

export const axis = { x: 0.81, y: 0.69 };

const Matrixfordashboard = ({ Examid }) => {
  const [dataPoints, setDataPoints] = useState([]);
  const canvasRef = useRef(null);
  console.log(Examid);

  let { id } = useParams();

  useEffect(() => {
    const fakeData = [{ x: 0.81, y: 0.69 }];
    setDataPoints(Examid);
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  }, [Examid]);

  const drawScatterPlot = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;

    // Clear canvas before drawing
    ctx.clearRect(0, 0, width, height);

    drawAxesAndPlot(ctx, width, height);
  };

  const drawAxesAndPlot = (ctx, width, height) => {
    const xMin = 0;
    const xMax = 1;
    const yMin = 0;
    const yMax = 1;

    const scaleX = (x) => (x - xMin) / (xMax - xMin) * (width - 60) + 50;
    const scaleY = (y) => height - ((y - yMin) / (yMax - yMin) * (height - 40)) - 20;

    // Draw axes
    ctx.beginPath();
    ctx.moveTo(50, 20);
    ctx.lineTo(50, height - 20);
    ctx.lineTo(width - 20, height - 20);
    ctx.strokeStyle = '#ffffff0a';
    ctx.stroke();

    // Draw ticks and labels
    ctx.font = '14px Arial';
    ctx.fillStyle = '#ffffff0a';
    for (let i = 0; i <= 10; i++) {
      const xPos = scaleX(i * 0.1);
      const yPos = scaleY(i * 0.1);
      ctx.beginPath();
      ctx.moveTo(xPos, height - 20);
      ctx.lineTo(xPos, height - 30);
      ctx.stroke();
      ctx.fillText(`${(i * 0.1).toFixed(1)}`, xPos - 10, height - 5);
      ctx.beginPath();
      ctx.moveTo(50, yPos);
      ctx.lineTo(40, yPos);
      ctx.stroke();
      ctx.fillText(`${(i * 0.1).toFixed(1)}`, 15, yPos + 5);
    }

    // Plot points
    dataPoints.forEach((point) => {
      const x = scaleX(point.x);
      const y = scaleY(point.y);

      const image = new Image();
      image.src = transparent;

      image.onload = () => {
        const imageSize = 150;
        ctx.globalAlpha = 0.99;
        ctx.drawImage(image, x - imageSize / 2, y - imageSize / 2, imageSize, imageSize);
        ctx.globalAlpha = 1;
      };
    });
  };

  useEffect(() => {
    if (dataPoints.length > 0) {
      drawScatterPlot();
    }
  }, [dataPoints]);

  return (

    <div className='matrixcanva'>
    {/* <h1 style={{color:'black'}}>{Examid}</h1> */}
    <canvas
      ref={canvasRef}
      width={850}
      height={650}
      style={{
        position: 'relative',
        zIndex: 999999,
        border: '1px solid white',
      }}
    ></canvas>

    <div>

      <div className='big-boxer'>
        
<div className="coding1vx031y036">
<div>
<p className='codingwrite1'>Coding</p>

</div>

</div>
<div className='coding2y50'>

</div>
<div className='datax15y7'>
<div>
<p className='datawrite1'>Data</p>

</div>
</div>
<div className='data2'>
<div>
<p className='codingwrite1'>Data</p>

</div>
</div>
<div className='technology1'>
<div>
<p className='Technologywrite1'>Technology</p>

</div>

</div>
<div className='technology2'>
<div>
<p className='codingwrite1'>Technology</p>

</div>
</div>
<div className='bussiness1'>
<div>
<p className='Bussinesswrite1'>Business</p>

</div>
</div>
<div className='bussiness2'>

</div>
<div className='marketing1'>
<div>
<p className='Bussinesswrite1'>Marketing</p>

</div>
</div>
<div className='marketing2'>
<div>
<p className='Bussinesswrite1'>Marketing</p>

</div>
</div>
<div className='finance1'>
<div>
<p className='Bussinesswrite1'>Finance</p>

</div>
</div>
<div className='finance2'>
<div>
<p className='Bussinesswrite1'>Finance</p>

</div>
</div>
<div className='pg1'>
<div>
<p className='Bussinesswrite1'>Personal Growth</p>

</div>
</div>
<div className='pg2'>

</div>
<div className='hu1'>
<div>
<p className='Bussinesswrite1'>  Humanities</p>

</div>
</div>
<div className='hu2'>
<div>
<p className='Bussinesswrite1'>  Humanities</p>

</div>
</div>
<div className="separate">
<div>
<p className='Bussinesswrite1' style={{textAlign:"right"}}>  Design</p>

</div>
<div>
<p className='contentwrite1'>  Content</p>

</div>
</div>
css
Copy code

      </div>
    </div>
    </div>

  );
};

export default Matrixfordashboard;
