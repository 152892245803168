import React, { useEffect, useRef, useState } from 'react';
import Pagetestfordashboard from '../../Pagesmaker/Pagetestfordashboard';
import { useParams } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Downloadresult = () => {
  const [message, setMessage] = useState('');
  const { userId, examId } = useParams();
 useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const [setstatusmarks, setSetstatusmarks] = useState([]);
  const childRef = useRef();
  const [useridreport, setuseridreport] = useState([]);
  const [savesendexamid, setsaveexamid] = useState([]);
  const [blockid, setBlockid] = useState([]);
  const [blockid2, setBlockid2] = useState([]);
  const [examidfind, setExamidfind] = useState('');

  const handletruefalse = (data, id2) => {
    setBlockid(data);
    setBlockid2(id2);
    console.log('handletruefalse data:', data);
  };

  const handleChildData = (data) => {
    console.log('Received from child:', data);
    setSetstatusmarks(Number(data));
  };

  const callChildFunction = (id2) => {
    if (childRef.current) {
      childRef.current.alertMessage(id2);
    }
  };

  useEffect(() => {
    console.log('setstatusmarks:', setstatusmarks, 'Type:', typeof setstatusmarks);

    if (setstatusmarks === 100) {

      console.log('setstatusmarks reached 100. Closing tab in 2 seconds.');
      setMessage('Download complete! Closing tab in 2 seconds...');
      const timer = setTimeout(() => {
        window.close();
      }, 0);

      return () => clearTimeout(timer);
    }
  }, [setstatusmarks]);

useEffect(() => {
    setExamidfind(examId);
    callChildFunction(examId);
}, [  ])



  return (
    <>
      {/* <h1 style={{ color: 'black', fontSize: '30px' }}>
        Download Result (Type: {typeof setstatusmarks})
      </h1>
      <p style={{ color: 'black', fontSize: '30px' }}>User ID: {blockid}{userId}</p>
      <p style={{ color: 'black', fontSize: '30px' }}>Exam ID: {examId}</p>
      <div>
        <div style={{ color: 'black', fontSize: '30px' }}>{message}</div>
      </div>
      {blockid === false || blockid2 !== examId ? (
        <button
          className="newresultbutton"
          style={{ fontSize: '12px', padding: '9px' }}
          onClick={() => {
            setExamidfind(examId);
            callChildFunction(examId);
          }}
        >
          <i className="bi bi-download"></i>Result{' '}
          <span className="badge bg-success" style={{ fontWeight: '500' }}>
            Download
          </span>
        </button>
      ) : (
        <p className="" style={{ fontSize: '12px' }}>
          <i className="bi bi-download"></i>Result:{' '}
          <span className="badge bg-success" style={{ fontWeight: '500' }}>
            Downloading {setstatusmarks}%...
          </span>
        </p>
      )} */}

     <div>
     {/* <div className="loader">
          <div className="loader__bar"></div>
          <div className="loader__bar"></div>
          <div className="loader__bar"></div>
          <div className="loader__bar"></div>
          <div className="loader__bar"></div>
          <div className="loader__ball"></div>
        </div> */}
        <div className='fullwiped'  data-aos="fade">
      <div className="home-container"  >
        <div className="thankyou-box thankyou"   style={{  padding: '30px', borderRadius: '8px', boxShadow: '0 4px 20px rgba(0,0,0,0.1)', textAlign: 'center' , width: '360px' }}>
          <div className='imgarea'>

          <img 
          className='img1'
            src='https://static.wixstatic.com/media/5715af_a286bfb446374a96877a4193a1ef0933~mv2.png/v1/fill/w_114,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PAT%20Logo.png' 
            alt="Logo" 
            style={{ width: '30%', marginBottom: '15px' }} 
          />
          <img 
            src='https://static.wixstatic.com/media/77a25b_ec2fa92086954799bf72699afde7a666~mv2.png/v1/fill/w_191,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/VMKGBLUE.png' 
            alt="Logo" 
            style={{ width: '60%', marginBottom: '15px' }} 
          />
          </div>

          <h2 style={{ color: '#396AA9', margin: '28px 0' }}>Downloading...!</h2>
<p style={{ color: '#333', margin: '5px 0',fontWeight:'500' }}>Your download is in progress. Please wait while we complete the process.</p>
<p style={{ color: '#333', margin: '5px 0',fontWeight:'500' }}>Once the download is complete, This tab will close.</p>

        <div  className='loading33'>
        <div className="loader">
          <div className="loader__bar"></div>
          <div className="loader__bar"></div>
          <div className="loader__bar"></div>
          <div className="loader__bar"></div>
          <div className="loader__bar"></div>
          <div className="loader__ball"></div>
        </div>

            </div>

          <button 
            className=' ' 
            style={{ color: 'white', fontWeight: 500, background: '#396AA9', border: 'none', borderRadius: '4px', padding: '10px 20px', cursor: 'pointer', transition: 'background 0.3s' }} 
            // onClick={handleNav}
            onMouseEnter={e => e.currentTarget.style.background = '#FEBD16'} // Change color on hover
            onMouseLeave={e => e.currentTarget.style.background = '#396AA9'} // Revert color
          >
          Downloading {setstatusmarks}%
          </button>
        </div>
      </div>
    </div>

     </div>

      <Pagetestfordashboard
        ref={childRef}
        Userreportid={userId}
        loadingstatus={handletruefalse}
        sendDataToParent={handleChildData}
        comehelper={examId}
      />
    </>
  );
};

export default Downloadresult;
