import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { BASE_URL, healthlabels, Relationshpslabels, selfAwarenessTopics, wealthlabel, workeducationlabel } from '../components/APi';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Pagetestfordashboard from '../Pagesmaker/Pagetestfordashboard';

const Examresult = () => {
  const { id } = useParams();
  const { pointindex } = useParams();
// const [examidfind, setExamidfind] = useState('')

console.log({"id":id,"pointindex":pointindex})
const [data, setData] = useState([])
const[labels,setlabels]=useState([])
const [currentsubject, setCurrentsubject] = useState('relationship')
const childRef = useRef();
const [useridreport,setuseridreport]=useState([])
const[savesendexamid,setsaveexamid]=useState([])
useEffect(() => {
   
  axios.get(`${BASE_URL}/exam/getexam/${id}` , {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('staffToken') }
}).then((response) => {
    console.log(response.data)
    setuseridreport(response.data.userId)
    setsaveexamid(response.data.id)
    axios.get(`${BASE_URL}/${currentsubject}/get/${response.data[`${currentsubject}id`]}` , {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('staffToken') }
    }).then((response) => {
         
              
    if(response.data.name=="relationship") {
        
        setlabels(Relationshpslabels)

        const cleanedData = Object.fromEntries(
            Object.entries(response.data).map(([key, { questions, name }]) => [key, { questions, name }])
        );
        console.log(cleanedData)
        
        const cleanedData1 = Object.fromEntries(
            Object.entries(cleanedData).map(([key, { questions  }]) => [key, { questions  }])
        );
       console.log(cleanedData1)
          
       const allQuestionsWithKeys = Object.entries(cleanedData1).flatMap(([key, value]) => 
        Array.isArray(value.questions) ? 
        value.questions.map(question => ({ area: key, ...question })) : []
      );
      
      const mappedResults = allQuestionsWithKeys.map(question => {
        const { area, answer } = question;
        const answerText = answer.text;
    
        return {
            area: area,
            Applicable: answerText === 'Not Applicable' || answerText === 'Applicable' ? answerText : "",
            Working: answerText === 'Not Working' || answerText === 'Working' ? answerText : "",
            Critical: ['Not Critical', 'Somewhat Critical', 'Critical', 'Very Critical', 'Most Critical'].includes(answerText) ? answerText : "",
            Future: answerText === 'Necessity' || answerText === 'Wish/Want/Desire' || answerText === 'Dream' || 
                    answerText === 'Hope' || answerText === 'Miracle' ? answerText : ""
        };
    });
    
    const uniqueData = mappedResults.reduce((acc, item) => {
        const existing = acc.find(entry => entry.area === item.area);
        
        if (existing) {
          existing.Applicable = existing.Applicable || item.Applicable || '';
          existing.Working = existing.Working || item.Working || '';
          existing.Critical = existing.Critical || item.Critical || '';
          existing.Future = existing.Future || item.Future || '';
        } else {
          acc.push({
            area: item.area,
            Applicable: item.Applicable || '',
            Working: item.Working || '',
            Critical: item.Critical || '',
            Future: item.Future || '',
          });
        }
        
        return acc;
      }, []);
      
      // Step 2: Map to the desired structure
      const data = uniqueData.map(question => {
        const applicable = question.Applicable || '';
        const working = question.Working || '';
        const critical = question.Critical || '';
        const future = question.Future || '';
      
        // Create an array for selected values based on conditions
        const selectedArray = [
          applicable  ,
          working ,
          critical  ,
          future 
        ];
      
        // Count how many selected values are not default
        const currentCount = selectedArray.filter(value => value.length !==0 &&  value.length !==0 &&  value.length !==0 &&  value.length !==0).length-1;
      
        return {
          selected: selectedArray,
          current: currentCount // Store the count of filled conditions
        };
      });
  console.log(data)
  setData(data)}
  if(response.data.name=="health"){
    setlabels(healthlabels)
      
              
    const cleanedData = Object.fromEntries(
        Object.entries(response.data).map(([key, { healthQuestion, name }]) => [key, { healthQuestion, name }])
    );
    console.log(cleanedData)
    
    const cleanedData1 = Object.fromEntries(
        Object.entries(cleanedData).map(([key, { healthQuestion  }]) => [key, { healthQuestion  }])
    );
   console.log(cleanedData1)
      
   const allQuestionsWithKeys = Object.entries(cleanedData1).flatMap(([key, value]) => 
    Array.isArray(value.healthQuestion) ? 
    value.healthQuestion.map(question => ({ area: key, ...question })) : []
  );
  console.log(allQuestionsWithKeys)

  const mappedResults = allQuestionsWithKeys.map(question => {
    const { area, healthAnswer } = question;
    const answerText = healthAnswer.text;

    return {
        area: area,
        Applicable: answerText === 'Not Applicable' || answerText === 'Applicable' ? answerText : "",
        Working: answerText === 'Not Working' || answerText === 'Working' ? answerText : "",
        Critical: ['Not Critical', 'Somewhat Critical', 'Critical', 'Very Critical', 'Most Critical'].includes(answerText) ? answerText : "",
        Future: answerText === 'Necessity' || answerText === 'Wish/Want/Desire' || answerText === 'Dream' || 
                answerText === 'Hope' || answerText === 'Miracle' ? answerText : ""
    };
});

const uniqueData = mappedResults.reduce((acc, item) => {
    const existing = acc.find(entry => entry.area === item.area);
    
    if (existing) {
      existing.Applicable = existing.Applicable || item.Applicable || '';
      existing.Working = existing.Working || item.Working || '';
      existing.Critical = existing.Critical || item.Critical || '';
      existing.Future = existing.Future || item.Future || '';
    } else {
      acc.push({
        area: item.area,
        Applicable: item.Applicable || '',
        Working: item.Working || '',
        Critical: item.Critical || '',
        Future: item.Future || '',
      });
    }
    
    return acc;
  }, []);
  
  // Step 2: Map to the desired structure
  const data = uniqueData.map(question => {
    const applicable = question.Applicable || '';
    const working = question.Working || '';
    const critical = question.Critical || '';
    const future = question.Future || '';
  
    // Create an array for selected values based on conditions
    const selectedArray = [
      applicable  ,
      working ,
      critical  ,
      future 
    ];
  
    // Count how many selected values are not default
    const currentCount = selectedArray.filter(value => value.length !==0 &&  value.length !==0 &&  value.length !==0 &&  value.length !==0).length-1;
  
    return {
      selected: selectedArray,
      current: currentCount // Store the count of filled conditions
    };
  });
  setData(data)
  }
if(response.data.name=='wealth'){
  setlabels(wealthlabel)
    
  const cleanedData = Object.fromEntries(
    Object.entries(response.data).map(([key, { wealthQuestion, name }]) => [key, { wealthQuestion, name }])
);
console.log(cleanedData)

const cleanedData1 = Object.fromEntries(
    Object.entries(cleanedData).map(([key, { wealthQuestion  }]) => [key, { wealthQuestion  }])
);
console.log(cleanedData1)
  
const allQuestionsWithKeys = Object.entries(cleanedData1).flatMap(([key, value]) => 
Array.isArray(value.wealthQuestion) ? 
value.wealthQuestion.map(question => ({ area: key, ...question })) : []
);
console.log(allQuestionsWithKeys)

const mappedResults = allQuestionsWithKeys.map(question => {
const { area, wealthAnswer } = question;
const answerText = wealthAnswer.text;

return {
    area: area,
    Applicable: answerText === 'Not Applicable' || answerText === 'Applicable' ? answerText : "",
    Working: answerText === 'Not Working' || answerText === 'Working' ? answerText : "",
    Critical: ['Not Critical', 'Somewhat Critical', 'Critical', 'Very Critical', 'Most Critical'].includes(answerText) ? answerText : "",
    Future: answerText === 'Necessity' || answerText === 'Wish/Want/Desire' || answerText === 'Dream' || 
            answerText === 'Hope' || answerText === 'Miracle' ? answerText : ""
};
});

const uniqueData = mappedResults.reduce((acc, item) => {
const existing = acc.find(entry => entry.area === item.area);

if (existing) {
  existing.Applicable = existing.Applicable || item.Applicable || '';
  existing.Working = existing.Working || item.Working || '';
  existing.Critical = existing.Critical || item.Critical || '';
  existing.Future = existing.Future || item.Future || '';
} else {
  acc.push({
    area: item.area,
    Applicable: item.Applicable || '',
    Working: item.Working || '',
    Critical: item.Critical || '',
    Future: item.Future || '',
  });
}

return acc;
}, []);

// Step 2: Map to the desired structure
const data = uniqueData.map(question => {
const applicable = question.Applicable || '';
const working = question.Working || '';
const critical = question.Critical || '';
const future = question.Future || '';

// Create an array for selected values based on conditions
const selectedArray = [
  applicable  ,
  working ,
  critical  ,
  future 
];

// Count how many selected values are not default
const currentCount = selectedArray.filter(value => value.length !==0 &&  value.length !==0 &&  value.length !==0 &&  value.length !==0).length-1;

return {
  selected: selectedArray,
  current: currentCount  
};
});
setData(data)
}
if(response.data.name=='Work/Education'){
  setlabels(workeducationlabel)
    
  const cleanedData = Object.fromEntries(
    Object.entries(response.data).map(([key, { workEducationQuestions, name }]) => [key, { workEducationQuestions, name }])
);
console.log(cleanedData)

const cleanedData1 = Object.fromEntries(
    Object.entries(cleanedData).map(([key, { workEducationQuestions  }]) => [key, { workEducationQuestions  }])
);
console.log(cleanedData1)
  
const allQuestionsWithKeys = Object.entries(cleanedData1).flatMap(([key, value]) => 
Array.isArray(value.workEducationQuestions) ? 
value.workEducationQuestions.map(question => ({ area: key, ...question })) : []
);
console.log(allQuestionsWithKeys)

const mappedResults = allQuestionsWithKeys.map(question => {
const { area, workEducationAnswer } = question;
const answerText = workEducationAnswer.text;

return {
    area: area,
    Applicable: answerText === 'Not Applicable' || answerText === 'Applicable' ? answerText : "",
    Working: answerText === 'Not Working' || answerText === 'Working' ? answerText : "",
    Critical: ['Not Critical', 'Somewhat Critical', 'Critical', 'Very Critical', 'Most Critical'].includes(answerText) ? answerText : "",
    Future: answerText === 'Necessity' || answerText === 'Wish/Want/Desire' || answerText === 'Dream' || 
            answerText === 'Hope' || answerText === 'Miracle' ? answerText : ""
};
});

const uniqueData = mappedResults.reduce((acc, item) => {
const existing = acc.find(entry => entry.area === item.area);

if (existing) {
  existing.Applicable = existing.Applicable || item.Applicable || '';
  existing.Working = existing.Working || item.Working || '';
  existing.Critical = existing.Critical || item.Critical || '';
  existing.Future = existing.Future || item.Future || '';
} else {
  acc.push({
    area: item.area,
    Applicable: item.Applicable || '',
    Working: item.Working || '',
    Critical: item.Critical || '',
    Future: item.Future || '',
  });
}

return acc;
}, []);

// Step 2: Map to the desired structure
const data = uniqueData.map(question => {
const applicable = question.Applicable || '';
const working = question.Working || '';
const critical = question.Critical || '';
const future = question.Future || '';

// Create an array for selected values based on conditions
const selectedArray = [
  applicable  ,
  working ,
  critical  ,
  future 
];

// Count how many selected values are not default
const currentCount = selectedArray.filter(value => value.length !==0 &&  value.length !==0 &&  value.length !==0 &&  value.length !==0).length-1;

return {
  selected: selectedArray,
  current: currentCount  
};
});
setData(data)

}
if(response.data.name=='Self Awareness'){
  setlabels(selfAwarenessTopics)
   
  const cleanedData = Object.fromEntries(
    Object.entries(response.data).map(([key, { selfAwarenessQuestions, name }]) => [key, { selfAwarenessQuestions, name }])
);
console.log(cleanedData)

const cleanedData1 = Object.fromEntries(
    Object.entries(cleanedData).map(([key, { selfAwarenessQuestions  }]) => [key, { selfAwarenessQuestions  }])
);
console.log(cleanedData1)
  
const allQuestionsWithKeys = Object.entries(cleanedData1).flatMap(([key, value]) => 
Array.isArray(value.selfAwarenessQuestions) ? 
value.selfAwarenessQuestions.map(question => ({ area: key, ...question })) : []
);
console.log(allQuestionsWithKeys)

const mappedResults = allQuestionsWithKeys.map(question => {
const { area, selfAwarenessAnswer } = question;
const answerText = selfAwarenessAnswer.text;

return {
    area: area,
    Applicable: answerText === 'Not Applicable' || answerText === 'Applicable' ? answerText : "",
    Working: answerText === 'Not Working' || answerText === 'Working' ? answerText : "",
    Critical: ['Not Critical', 'Somewhat Critical', 'Critical', 'Very Critical', 'Most Critical'].includes(answerText) ? answerText : "",
    Future: answerText === 'Necessity' || answerText === 'Wish/Want/Desire' || answerText === 'Dream' || 
            answerText === 'Hope' || answerText === 'Miracle' ? answerText : ""
};
});

const uniqueData = mappedResults.reduce((acc, item) => {
const existing = acc.find(entry => entry.area === item.area);

if (existing) {
  existing.Applicable = existing.Applicable || item.Applicable || '';
  existing.Working = existing.Working || item.Working || '';
  existing.Critical = existing.Critical || item.Critical || '';
  existing.Future = existing.Future || item.Future || '';
} else {
  acc.push({
    area: item.area,
    Applicable: item.Applicable || '',
    Working: item.Working || '',
    Critical: item.Critical || '',
    Future: item.Future || '',
  });
}

return acc;
}, []);

// Step 2: Map to the desired structure
const data = uniqueData.map(question => {
const applicable = question.Applicable || '';
const working = question.Working || '';
const critical = question.Critical || '';
const future = question.Future || '';

// Create an array for selected values based on conditions
const selectedArray = [
  applicable  ,
  working ,
  critical  ,
  future 
];

// Count how many selected values are not default
const currentCount = selectedArray.filter(value => value.length !==0 &&  value.length !==0 &&  value.length !==0 &&  value.length !==0).length-1;

return {
  selected: selectedArray,
  current: currentCount  
};
});
setData(data)
}
    }).catch((error)=>{
    console.log(error)
     })
}).catch((error)=>{
    console.log(error)
           
})

}, [ currentsubject])

// const labels = 
console.log(Relationshpslabels)
console.log(currentsubject)
const [setstatusmarks, setSetstatusmarks] = useState([])

const [blockid, setBlockid] = useState([])
const [blockid2, setBlockid2] = useState([])
const [examidfind, setExamidfind] = useState('')

const handletruefalse=(data,id2)=>{
  setBlockid(data)
  setBlockid2(id2)
  
  console.log(data)
}
const handleChildData = (data) => {
  console.log("Received from child:", data);
 setSetstatusmarks(data)
};


// Function to call the child component's method
const callChildFunction = (id2) => {
  if (childRef.current) {
    childRef.current.alertMessage(id2); 
  }
};




  return (
<>
<Sidebar/>
    <div className="containerNormal mainContainerlarge">
        <Navbar/>
        <div className='container'>
<div className='card'>
<div className='card-header'>
    <h5>Exam Result</h5>


    <div className='flexsubjects'>

    { blockid === false || blockid2 !== savesendexamid ? (
                  <button
                    className="newresultbutton"
                    style={{ fontSize: '12px', padding: '9px' }}
                    onClick={() => {
                      setExamidfind(savesendexamid);
                      callChildFunction(savesendexamid);
                    }}
                  >
                    <i className="bi bi-download"></i>Result{" "}
                    <span
                      className="badge bg-success"
                      style={{ fontWeight: '500' }}
                    >
                      Download
                    </span>
                  </button>
                ) : (
                  <p className="" style={{ fontSize: '12px' }}>
                    <i className="bi bi-download"></i>Result:{" "}
                    <span
                      className="badge bg-success"
                      style={{ fontWeight: '500' }}
                    >
                      Downloading {setstatusmarks}%...
                    </span>
                  </p>
                )}

    <div className={`cursor ${currentsubject === 'relationship' ? 'active12' : ''}`} onClick={() => setCurrentsubject('relationship')}>
    Relationships
</div>
<div className={`cursor ${currentsubject === 'health' ? 'active12' : ''}`} onClick={() => setCurrentsubject('health')}>
    Health
</div>
<div className={`cursor ${currentsubject === 'wealth' ? 'active12' : ''}`} onClick={() => setCurrentsubject('wealth')}>
    Wealth
</div>
<div className={`cursor ${currentsubject === 'workeducation' ? 'active12' : ''}`} onClick={() => setCurrentsubject('workeducation')}>
    Work Education
</div>
<div className={`cursor ${currentsubject === 'selfawareness' ? 'active12' : ''}`} onClick={() => setCurrentsubject('selfawareness')}>
    Self Awareness
</div>

    </div>
</div>

<div className='card-body'>
<div className='row'>
                            <div className='col black'>Area</div>
                            <div className='col black question'>Is this area applicable for you?</div>
                            <div className='col black question'>Is this area working for you?</div>
                            <div className='col black question'>How critical is this area for you?</div>
                            <div className='col black question'>How do you see your near future in this area?</div>
                        </div>

            
                <div className=''>
{
    labels.map((item,index1)=>{
       return <>
     <div className='row'>
                            <div className='col black'>{item}</div>
                            <div className='col black question'>
  {data.filter((el, index) => index === index1).map((item) => item.selected)[0]?.[0] || ""}
</div>
<div className='col black question'>
  {data.filter((el, index) => index === index1).map((item) => item.selected)[0]?.[1] || " "}
</div>
<div className='col black question'>
  {data.filter((el, index) => index === index1).map((item) => item.selected)[0]?.[2] || " "}
</div>
<div className='col black question'>
  {data.filter((el, index) => index === index1).map((item) => item.selected)[0]?.[3] || " "}
</div>

                        </div>
        </>
    })
}
                    </div>
              
    </div>
</div>

    </div>
    </div>


    <Pagetestfordashboard ref={childRef} Userreportid={useridreport} loadingstatus={handletruefalse} sendDataToParent={handleChildData}  comehelper={examidfind}  />

</>
  
  )
}

export default Examresult