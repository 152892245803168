import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Select from 'react-select';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { baseurlvmkp } from '../Files/Subjects/Baseurl';
import axios from 'axios';

const genderOptions = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Other', value: 'Other' },
];

const maritalStatusOptions = [
  { label: 'Single', value: 'Single' },
  { label: 'Married', value: 'Married' },
  { label: 'Divorced', value: 'Divorced' },
  { label: 'Widowed', value: 'Widowed' },
];

const occupationOptions = [
  { value: 'student', label: 'Student' },
  { value: 'Employed', label: 'Employed' },
  { value: 'Unemployed', label: 'Unemployed' },
  { value: 'Self-employed', label: 'Self-employed' },
  { value: 'Business-Person', label: 'Business Person' },
];

const Modalc2 = ({ isModalOpen, closeModal }) => {
  const [formData, setFormData] = useState({
  
  });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [availableDays, setAvailableDays] = useState([]);
const [password, setPassword] = useState('')
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    // console.log(userData.details.id)
    axios.get(`${baseurlvmkp}/vmkp/get/${userData.details.id}`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      }).then((response) => {
        // console.log(response.data.states.id);  
        // setUsername(response.data);  
        setFormData({
            ...response.data,
            country: response.data.country.id ,
            states:response.data.states.id,
            city:response.data.city.id,
            password:''
          });
          fetchStates(response.data.country.id)
          fetchCities(response.data.states.id)
          // console.log(response.data.states.id)
          // console.log(response.data.birth_date)
          const birthDateString = response.data.birth_date;

 
           const [year, month, day] = birthDateString.split('-');
          
          //  console.log("Year:", year);   
          // console.log("Month:", month);   
          // console.log("Day:", day); 
          setSelectedYear(year)
          setSelectedMonth(month)
          setSelectedDate(day)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    fetch(`${baseurlvmkp}/country/get`)
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.map((item) => ({ label: item.name, value: item.id })));
      })
      .catch((error) => console.error('Error fetching countries:', error));
      
  }, [isModalOpen,closeModal]);

  const fetchStates = (countryId) => {
    fetch(`${baseurlvmkp}/state/get/${countryId}`)
      .then((response) => response.json())
      .then((data) => {
        setStates(data.map((item) => ({ label: item.name, value: item.id })));
        // setCities([]);
        // setFormData((prevData) => ({ ...prevData, states: '', city: '' }));
      })
      .catch((error) => console.error('Error fetching states:', error));
  };

  const fetchCities = (stateId) => {
    fetch(`${baseurlvmkp}/city/get/${stateId}`)
      .then((response) => response.json())
      .then((data) => {
        setCities(data.map((item) => ({ label: item.name, value: item.id })));
      })
      .catch((error) => console.error('Error fetching cities:', error));
  };

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === 'country' ? { states: '', city: '' } : {}),
    }));

    if (name === 'country') {
      fetchStates(value);
    } else if (name === 'states') {
      fetchCities(value);
    }
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.first_name) newErrors.first_name = 'First name is required';
    if (!formData.last_name) newErrors.last_name = 'Last name is required';
    if (!formData.phone_no) newErrors.phone_no = 'Phone number is required';
    if (!formData.email) newErrors.email = 'Email is required';
    // if (!formData.password) newErrors.password = 'Password is required';
    if (!formData.gender) newErrors.gender = 'Gender is required';
    if (!formData.marital_status) newErrors.marital_status = 'Marital status is required';
    if (!formData.country) newErrors.country = 'Country is required';
    if (!formData.states) newErrors.states = 'State is required';
    if (!formData.city) newErrors.city = 'City is required';
    if (!formData.occupation) newErrors.occupation = 'Occupation is required';
    if (!selectedYear || !selectedMonth || !selectedDate) newErrors.dob = 'Date of Birth is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
const [erroremail, setErroremail] = useState(false)
const [loginloading, setLoginloading] = useState(false)
  const handleSubmit = () => {
    // console.log(formData)
    if (validate()) {
      const dob = `${selectedDate}-${selectedMonth}-${selectedYear}`;
      const age = calculateAge(dob);
      const dob2 = `${selectedYear}-${String(selectedMonth).padStart(2, '0')}-${String(selectedDate).padStart(2, '0')}`;

      setFormData((prevData) => ({
        ...prevData,
        birth_date:dob2,
        age,
        country: { id: formData.country },  
        states:{id:formData.states},
        city:{id:formData.city},
        password:password
      }));
      const formData1 = {
        ...formData,
        // Only include the password field if it's not empty
        ...(password ? { password: password } : {}),
      };
      
      const payload = {
        ...formData,
        birth_date:dob2,
        age,
        country: { id: formData.country },  
        states:{id:formData.states},
        city:{id:formData.city}
      };
      setLoginloading(true);
      axios.put(`${baseurlvmkp}/vmkp/put/${formData.id}`, { ...formData1,
        birth_date:dob2,
        age,
        country: { id: formData.country },  
        states:{id:formData.states},
        city:{id:formData.city} })
      .then((response) => {
        setLoginloading(false)
        closeModal();
        console.log('Success:', response.data);
        Swal.fire({
          title: 'Success!',
          text: 'Form submitted successfully!',
          icon: 'success',
          confirmButtonText: 'OK',
        });
        closeModal();
        setErroremail(false)

        // navigate('/LOgionnew')
      })

      .catch((error) => {
        setLoginloading(false)
        console.error('Error:', error.response.data);
        // closeModal();
if(error.response.data=='Email Already Exist'){
  Swal.fire({
    title: 'Error!',
    text: 'This email already Exist',
    icon: 'error',
    confirmButtonText: 'OK',

  });
  setErroremail(true)
}
      else { 
        
        Swal.fire({
          title: 'Error!',
          text: 'There was an error submitting the form.',
          icon: 'error',
          confirmButtonText: 'OK',
        })
      
      }
      });




      console.log('Form Data:', { ...formData, dob, age });
      Swal.fire('Submitted!', 'Your data has been saved.', 'success');
    } else {
      Swal.fire('Error', 'Please fix the errors in the form.', 'error');
    }
  };

  const years = Array.from({ length: 100 }, (_, i) => ({
    label: `${2024 - i}`, value: `${2024 - i}`,
  }));

  const months = [
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' },
  ];

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
      const days = Array.from({ length: daysInMonth }, (_, i) => ({
        label: `${i + 1}`, value: `${i + 1}`,
      }));
      setAvailableDays(days);
    } else {
      setAvailableDays([]);
    }
  }, [selectedYear, selectedMonth]);

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: 'black',
      '&:hover': {
        borderColor: 'black',
      },
    }),
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f0f8ff" : "#ffffff", 
      borderColor: state.isFocused ? "#007bff" : "#ced4da", 
      boxShadow: state.isFocused ? "0 0 0 1px #007bff" : null,
      ":hover": {
        borderColor: "#007bff",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#007bff" 
        : state.isFocused
        ? "#e9f5ff" 
        : null,
      color: state.isSelected ? "#ffffff" : "#000000",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d", 
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#495057", 
    }),
  };
const [country11, setcountry11] = useState([])
const [state11, setstate11] = useState([])
const [city11,setcity11]=useState([])
// console.log(states)
   return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <div style={{ background: '#fff', padding: '30px', borderRadius: '10px', width: '800px' }}>
        <Typography id="modal-title" variant="h5" align="center" gutterBottom>
          Edit Profile
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="First Name"
              name="first_name"
              value={formData.first_name}
              onChange={(e) => handleChange('first_name', e.target.value)}
              error={!!errors.first_name}
              helperText={errors.first_name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Last Name"
              name="last_name"
              value={formData.last_name}
              onChange={(e) => handleChange('last_name', e.target.value)}
              error={!!errors.last_name}
              helperText={errors.last_name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phone_no"
              value={formData.phone_no}
              onChange={(e) => handleChange('phone_no', e.target.value)}
              error={!!errors.phone_no}
              helperText={errors.phone_no}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={(e) => handleChange('email', e.target.value)}
              error={!!errors.email}
              helperText={errors.email}
            />
            {erroremail?<span style={{color:'red'}}>This E-mail Already Exist</span>:''}
          </Grid>
          
          <Grid item xs={6}>
            <TextField
              fullWidth
              type={showPassword ? 'text' : 'password'}
              label="Password"
              name="password"
            //   value={formData.password}
            autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)}
              error={!!errors.password}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
               styles={customStyles}
              options={maritalStatusOptions}
              value={{label:maritalStatusOptions.filter((item)=>item.label==formData.marital_status).map((item)=>item.label),value:maritalStatusOptions.filter((item)=>item.label==formData.marital_status).map((item)=>item.value)}}

              onChange={(option) => handleChange('marital_status', option.value)}
              placeholder="Marital Status"
              isSearchable
            />
            {errors.marital_status && <Typography color="error">{errors.marital_status}</Typography>}
          </Grid>
          <Grid item xs={12}>
            <span style={{color:'black'}}>DOB:</span>
            <div style={{ display: 'flex', gap:'6px' }}>
              <Select
                styles={customStyles}
                options={years}
                value={selectedYear ? { label: selectedYear, value: selectedYear } : null}
                onChange={(e) => {setSelectedYear(e.value);setSelectedMonth();setSelectedDate()}}
                placeholder="Year"
              />
              <Select
                options={months}
                styles={customStyles}
                value={selectedMonth ? { label: months.filter((item)=>item.value==selectedMonth).map((item)=>item.label), value: selectedMonth } : null}
                onChange={(e) => setSelectedMonth(e.value)}
                placeholder="Month"
              />
              <Select
                options={availableDays}
                styles={customStyles}
                value={selectedDate ? { label: selectedDate, value: selectedDate } : null}
                onChange={(e) => setSelectedDate(e.value)}
                placeholder="Day"
                isDisabled={!availableDays.length}
              />
            </div>
            {errors.dob && <Typography color="error">{errors.dob}</Typography>}
          </Grid>
          <Grid item xs={6}>
            <Select
              // styles={customSelectStyles}
              options={genderOptions}
              styles={customStyles}
              onChange={(option) => handleChange('gender', option.value)}
              placeholder="Gender"
              value={
                formData.gender
                  ? {
                      label: genderOptions.find((item) => item.value === formData.gender)?.label,
                      value: genderOptions.find((item) => item.value === formData.gender)?.value,
                    }
                  : null
              }     
              
              isSearchable
            />
            {errors.gender && <Typography color="error">{errors.gender}</Typography>}
          </Grid>
          <Grid item xs={6}>
            <Select
  styles={customStyles}         
       options={countries}
      
              value={{label:countries.filter((item)=>item.value==formData.country).map((item)=>item.label),value:countries.filter((item)=>item.value==formData.country).map((item)=>item.value)}}
              onChange={(option) =>{ handleChange('country', option.value); setcountry11(option)}}
              placeholder="Country"
             

              isSearchable
            />
            {/* {console.log(countries)} */}
            {/* {console.log(countries.filter((item)=>item.value==101) )} */}
            {errors.country && <Typography color="error">{errors.country}</Typography>}
          </Grid>
          <Grid item xs={6}>
            <Select
             styles={customStyles}
              options={states}
              value={{label:states.filter((item)=>item.value==formData.states).map((item)=>item.label),value:states.filter((item)=>item.value==formData.states).map((item)=>item.value)}}
              onChange={(option) => {handleChange('states', option.value);setstate11(option)}}
              placeholder="State"
              isSearchable
            />
            {errors.states && <Typography color="error">{errors.states}</Typography>}
          </Grid>
          {/* {console.log(formData)} */}
           <Grid item xs={6}>
            <Select
             styles={customStyles}
              options={cities}
            //   value={city11}
              value={{label:cities.filter((item)=>item.value==formData.city).map((item)=>item.label),value:cities.filter((item)=>item.value==formData.city).map((item)=>item.value)}}

              onChange={(option) =>{ handleChange('city', option.value);setcity11(option)}}
              placeholder="City"
              isSearchable
            />
            {errors.city && <Typography color="error">{errors.city}</Typography>}
          </Grid>
          <Grid item xs={6}>
            <Select
             styles={customStyles}
              options={occupationOptions}
              value={{label:occupationOptions.filter((item)=>item.value==formData.occupation).map((item)=>item.label),value:occupationOptions.filter((item)=>item.value==formData.occupation).map((item)=>item.value)}}
              onChange={(option) => handleChange('occupation', option.value)}
              placeholder="Occupation"
              isSearchable
            />
            {errors.occupation && <Typography color="error">{errors.occupation}</Typography>}
          </Grid>
          <Grid item xs={6}>
            {/* <TextField
              fullWidth
              label="Address"
              name="address"
              value={formData.address}
              onChange={(e) => handleChange('address', e.target.value)}
            /> */}
            <input type='text'  className='form-control downwordsaddress' name="address" placeholder='Address'  value={formData.address}
              onChange={(e) => handleChange('address', e.target.value)} />
          </Grid>
          <Grid item xs={12}>
            {loginloading==false?<Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>:<Button variant="contained" color="primary"  >
            <p style={{color:'black'}}><div class="loader12"></div></p>
            </Button> }
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default Modalc2;
