import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { baseurlvmkp } from './Files/Subjects/Baseurl';
import ModalComponent from './Pagesmaker/ModalComponent';

const LOgionnew = () => {
  const [view, setView] = useState('login'); // 'login' or 'forgot'
  const [email, setEmail] = useState('');
  const [passcode, setPasscode] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const [visible, setVisible] = useState(false);
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [step, setStep] = useState(1);
  const [otpError, setOtpError] = useState(false);
  const navigate = useNavigate();



  const [isModalOpen, setIsModalOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [showGenerateOtp, setShowGenerateOtp] = useState();

  useEffect(() => {
    


    fetch(`${baseurlvmkp}/country/get`)
      .then(response => response.json())
      .then(data => setCountries(data.map(item => ({ label: item.name, value: item.id }))))
      .catch(console.error);


      const timer = setTimeout(() => {
        setShowGenerateOtp(true);
      }, 30000);  
  
       return () => clearTimeout(timer);
  }, []);

  const fetchStates = (countryId) => {
    return fetch(`${baseurlvmkp}/state/get/${countryId}`)
      .then(response => response.json())
      .then(data => data.map(item => ({ label: item.name, value: item.id })))
      .catch(console.error);
  };

  const fetchCities = (stateId) => {
    return fetch(`${baseurlvmkp}/city/get/${stateId}`)
      .then(response => response.json())
      .then(data => data.map(item => ({ label: item.name, value: item.id })))
      .catch(console.error);
  };

const [loadingforlogin, setLoadingforlogin] = useState(false);
  const handleChangelogin = async () => {
    setLoadingforlogin(true);
    try {
      const response = await axios.post(`${baseurlvmkp}/vmkp/post/customer/signin`, {
        email,
        password: passcode,
      });

      const { token } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem("user", JSON.stringify(response.data));
      console.log('Login successful:', response.data);
      navigate('/Coustomerdashboard');
      setLoadingforlogin(false);
    } catch (error) {
      console.error('Login error:', error);
      setLoadingforlogin(false);
      Swal.fire({
        title: 'Login Failed',
        text: 'Invalid email or password.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };
const [loadingforgotpass, setLoadingforgotpass] = useState(false)
  const handleForgotPassword = async () => {
    setLoadingforgotpass(true );
    try {
      const response = await axios.post(`${baseurlvmkp}/forgot/post/send`, { email });
      Swal.fire({
        title: 'Email Sent',
        text: 'A password reset link or OTP has been sent to your email.',
        icon: 'success',
        confirmButtonText: 'OK',

      });
      setLoadingforgotpass(false );

      setStep(2);
    } catch (error) {
      setLoadingforgotpass(false );

      Swal.fire({
        title: 'Error',
        text: 'Unable to send password reset email. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };
const [verify, setVerify] = useState(false)
  const handleVerifyOtp = async () => {
    
    if (otp.length !== 6) {
      setOtpError(true);
      return;
    }

    try {
      setVerify(true);
      const response = await axios.post(`${baseurlvmkp}/forgot/post/verify`, { email, otp });
    
    if(response.data=='Time Limit Is Exceed Generate New Otp'){
      Swal.fire({
        title: 'Error',
        text: 'Time Limit Is Exceed Generate New Otp',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      setVerify(false);

    }else{
      Swal.fire({
        title: 'OTP Verified',
        text: 'Enter your new password to reset it.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
      setVerify(false);

      setStep(3);
    }
    
    
   
    
    } catch (error) {
      setVerify(false);

      Swal.fire({
        title: 'Error',
        text: 'Invalid OTP. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };
  const handleResetPassword = async () => {
    if (!email || !password) {
      Swal.fire({
        title: 'Validation Error',
        text: 'Please provide both email and password.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }
  
    try {
      setResetpass(true);
      const response = await axios.post(`${baseurlvmkp}/forgot/post/changepassword`, null, {
        params: { email, password },
      });
      setStep(1);
      setResetpass(false);
      Swal.fire({
        title: 'Password Reset',
        text: 'Your password has been successfully reset. You can now log in.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
      setView('login');
    } catch (error) {
      setResetpass(false);
      Swal.fire({
        title: 'Error',
        text: 'Unable to reset password. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };
  
  // const handleResetPassword = async () => {
  //   try {setResetpass(true);
  //     const response = await axios.post(`${baseurlvmkp}/forgot/post/changepassword`, null, {
  //       params: { email, password },
  //     });
  //     setStep(1)
  //     setResetpass(false);
  //     Swal.fire({
  //       title: 'Password Reset',
  //       text: 'Your password has been successfully reset. You can now log in.',
  //       icon: 'success',
  //       confirmButtonText: 'OK',
  //     });
  //     setView('login');
  //   } catch (error) {
  //     setResetpass(false);
  //     Swal.fire({
  //       title: 'Error',
  //       text: 'Unable to reset password. Please try again later.',
  //       icon: 'error',
  //       confirmButtonText: 'OK',
  //     });
  //   }
  // };
const [resetpass, setResetpass] = useState(false)
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (view === 'login') {
        handleChangelogin();
      } else if (view === 'forgot') {
        if (step === 1) handleForgotPassword();
        else if (step === 2) handleVerifyOtp();
        else if (step === 3) handleResetPassword();
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
<>


<ModalComponent
  isModalOpen={isModalOpen}
  closeModal={() => setIsModalOpen(false)}
/>

    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor="#f7f9fc"
      padding={2}
    >
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginBottom: 4 }}>
        <img
          style={{ padding: '12px', height: '67px' }}
          src="https://static.wixstatic.com/media/5715af_a286bfb446374a96877a4193a1ef0933~mv2.png/v1/fill/w_114,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PAT%20Logo.png"
          alt="PAT Logo"
        />
        <img
          style={{ padding: '12px', height: '52px' }}
          src="https://static.wixstatic.com/media/77a25b_ec2fa92086954799bf72699afde7a666~mv2.png/v1/fill/w_191,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/VMKGBLUE.png"
          alt="VMKGBLUE Logo"
        />
      </Box>

      <Paper elevation={3} sx={styles.card}>
        {view === 'login' ? (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              Login
            </Typography>
            <TextField
              variant="outlined"
              label="Email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyPress}
              required
              fullWidth
              sx={styles.textField}
            />
            <TextField
              variant="outlined"
              label="Password"
              type={visible ? "text" : "password"}
              onChange={(e) => setPasscode(e.target.value)}
              onKeyDown={handleKeyPress}
              required
              fullWidth
              sx={styles.textField}
            />

            <button
              id="eyebtn"
              onClick={(e) => {
                e.preventDefault();
                setVisible(!visible);
              }}
            >
              {visible ? <FaEye /> : <FaEyeSlash />}
            </button>
            <span
              // variant="text"
              // color="secondary"
              onClick={() => setView('forgot')}
              // fullWidth
            className='forgotpass'  
            >
              Forgot Password?
            </span>
           {loadingforlogin==false? <Button
              variant="contained"
              color="primary"
              onClick={handleChangelogin}
              fullWidth
              sx={styles.button}
              style={{marginTop:'-11px'}}
            >
              Login
            </Button>:
            
            <Button
              variant="contained"
              color="primary"
              // onClick={handleChangelogin}
              fullWidth
              sx={styles.button}
              style={{marginTop:'-11px'}}
            >
              <div class="loader12"></div>
            </Button>
            
            // <p style={{color:'black'}}><div class="loader12"></div></p>}
           }
            <div className='regaddress' style={{color:'black'}} onClick={() => setIsModalOpen(true)}> Sign Up </div >

           
          </>
        ) : (
          <>
            {step === 1 && (
              <>
                <Typography variant="h4" component="h2" align="center" gutterBottom>
                  Forgot Password
                </Typography>
                <TextField
                  variant="outlined"
                  label="Email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={handleKeyPress}
                  required
                  fullWidth
                  sx={styles.textField}
                />
            {loadingforgotpass==false?    <Button
                  variant="contained"
                  color="primary"
                  onClick={handleForgotPassword}
                  fullWidth
                  sx={styles.button}
                >
                  Send Reset Link
                </Button>: 
                <Button
                variant="contained"
                color="primary"
                // onClick={handleForgotPassword}
                fullWidth
                sx={styles.button}
              >
         <div class="loader12"></div>
              </Button>
                
                // <p style={{color:'black'}}><div class="loader12"></div></p>
                }
              </>
            )}
            {step === 2 && (
              <>
                <Typography variant="h4" component="h2" align="center" gutterBottom>
                  Enter OTP
                </Typography>
                <TextField
                  variant="outlined"
                  label="OTP"
                  type="number"
                  onChange={(e) => setOtp(e.target.value)}
                  onKeyDown={handleKeyPress}
                  value={otp}
                  required
                  fullWidth
                  sx={styles.textField}
                  error={otpError}
                  helperText={otpError ? 'Please enter a valid 6-digit OTP' : ''}
                />
   {   showGenerateOtp && (
      
        loadingforgotpass==false?<p
        style={{
          textTransform: 'capitalize',
          cursor: 'pointer',
          color: 'blue',
        }}
        onClick={handleForgotPassword}
      >
        Generate New OTP 
      </p>:<p style={{color:'black'}}><div class="loader12"></div></p> 
      
      )}


             {verify==false?   <Button
                  variant="contained"
                  color="primary"
                  onClick={handleVerifyOtp}
                  fullWidth
                  sx={styles.button}
                >
                  Verify OTP
                </Button>
                :<p style={{color:'black'}}><div class="loader12"></div></p> }
              </>
            )}
            {step === 3 && (
              <>
                <Typography variant="h4" component="h2" align="center" gutterBottom>
                  Reset Password
                </Typography>
                <TextField
                  variant="outlined"
                  label="New Password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyPress}
                  required
                  fullWidth
                  sx={styles.textField}
                />
              { resetpass==false? <Button
                  variant="contained"
                  color="primary"
                  onClick={handleResetPassword}
                  fullWidth
                  sx={styles.button}
                >
                  Reset Password
                </Button>:<p style={{color:'black'}}><div class="loader12"></div></p> }
              </>
            )}
            <Button
              variant="text"
              color="secondary"
              onClick={() => {setView('login'); setStep(1)}}
              fullWidth
              sx={styles.button}
            >
              Back to Login
            </Button>
          </>
        )}
      </Paper>
    </Box>

    </>
  );
};

const styles = {
  card: {
    padding: '20px',
    borderRadius: '8px',
    width: '400px',
    position: 'relative',
  },
  textField: {
    marginBottom: '15px',
  },
  button: {
    marginTop: '20px',
  },
};

export default LOgionnew;

 