import axios from "axios";
import { baseurlvmkp } from "./Baseurl";

export const Roamin = async (navigate) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    if (!user || !token) {
      console.error("User or token is missing from local storage.");
      return;
    }

    const response = await axios.get(
      `${baseurlvmkp}/exam/userstatus/${user.details.id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const status = response.data[0]?.status;

    if (status === true) {
      navigate("/Totorialstartpage");
    } else if (status === false) {
      navigate("/redirectpage");
    } else {
      console.error("Unexpected status value:", status);
    }
  } catch (error) {
    console.error("Error fetching user status:", error);
  }
};
export const Fortesresult = async (navigate) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = localStorage.getItem("token");
  
      if (!user || !token) {
        console.error("User or token is missing from local storage.");
        return;
      }
  
      const response = await axios.get(
        `${baseurlvmkp}/exam/userstatus/${user.details.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      const status = response.data[0]?.status;
  
      if (status === true) {
        navigate("/Coustomerdashboard");
      } else if (status === false) {
        // navigate("");
      } else {
        console.error("Unexpected status value:", status);
      }
    } catch (error) {
      console.error("Error fetching user status:", error);
    }
  };

  export const tokenexistchecker = async (navigate) => {
    try {
      if (localStorage.getItem('token')&&localStorage.getItem('details')) {
        console.log('Token exists in localStorage.');
      } else {
        console.log('Token does not exist in localStorage.');
        // navigate('/LOgionnew');
      }
    } catch (error) {
      console.error('An error occurred while checking for the token:', error);
      alert('An unexpected error occurred. Please try again later.');
    //   navigate('/LOgionnew');
    }
  };
  