import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { baseurlvmkp } from '../Files/Subjects/Baseurl';

const Loginforgot = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [step, setStep] = useState(1); // Step 1: Enter Email, Step 2: Enter OTP, Step 3: Reset Password
  const [otpError, setOtpError] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    try {
      const response = await axios.post(`${baseurlvmkp}/forgot/post/send`, { email });
      console.log('Password reset email sent:', response.data);
      Swal.fire({
        title: 'Email Sent',
        text: 'A password reset link or OTP has been sent to your email.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
      setStep(2); // Move to OTP step
    } catch (error) {
      console.error('Error sending password reset email:', error);
      Swal.fire({
        title: 'Error',
        text: 'Unable to send password reset email. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleVerifyOtp = async () => {
    if (otp.length !== 6) {
      setOtpError(true);
      return;
    }

    try {
      const response = await axios.post(`${baseurlvmkp}/forgot/post/verify`, { email, otp });
      console.log('OTP verified:', response.data);
      Swal.fire({
        title: 'OTP Verified',
        text: 'Enter your new password to reset it.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
      setStep(3); // Move to Reset Password step
    } catch (error) {
      console.error('Error verifying OTP:', error);
      Swal.fire({
        title: 'Error',
        text: 'Invalid OTP. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await axios.post(`${baseurlvmkp}/forgot/post/changepassword`, null, {
        params: {
          email,
          password,
        },
      });
      console.log('Password reset successful:', response.data);
      Swal.fire({
        title: 'Password Reset',
        text: 'Your password has been successfully reset. You can now log in.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
      navigate('/LOgionnew'); // Navigate to login page
    } catch (error) {
      console.error('Error resetting password:', error);
      Swal.fire({
        title: 'Error',
        text: 'Unable to reset password. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (step === 1) {
        handleForgotPassword();
      } else if (step === 2) {
        handleVerifyOtp();
      } else if (step === 3) {
        handleResetPassword();
      }
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor="#f7f9fc"
      padding={2}
    >
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginBottom: 4 }}>
        <img
          style={{ padding: '12px', height: '67px' }}
          src="https://static.wixstatic.com/media/5715af_a286bfb446374a96877a4193a1ef0933~mv2.png/v1/fill/w_114,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PAT%20Logo.png"
          alt="PAT Logo"
        />
        <img
          style={{ padding: '12px', height: '52px' }}
          src="https://static.wixstatic.com/media/77a25b_ec2fa92086954799bf72699afde7a666~mv2.png/v1/fill/w_191,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/VMKGBLUE.png"
          alt="VMKGBLUE Logo"
        />
      </Box>

      <Paper elevation={3} sx={styles.card}>
        {step === 1 && (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              Forgot Password
            </Typography>
            <Typography variant="body2" align="center" gutterBottom>
              Enter your email address below and we'll send you a link to reset your password.
            </Typography>
            <TextField
              variant="outlined"
              label="Email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyPress}
              required
              fullWidth
              sx={styles.textField}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleForgotPassword}
              fullWidth
              sx={styles.button}
            >
              Send Reset Link
            </Button>
          </>
        )}
        {step === 2 && (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              Enter OTP
            </Typography>
            <Typography variant="body2" align="center" gutterBottom>
              An OTP has been sent to your email. Enter it below to verify.
            </Typography>
            <TextField
              variant="outlined"
              label="OTP"
              type="number"
              onChange={(e) => setOtp(e.target.value)}
              onKeyDown={handleKeyPress}
              value={otp}
              required
              fullWidth
              sx={styles.textField}
              error={otpError}
              helperText={otpError ? 'Please enter a valid 6-digit OTP' : ''}
              inputProps={{
                maxLength: 6,
                inputMode: 'numeric',
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleVerifyOtp}
              fullWidth
              sx={styles.button}
            >
              Verify OTP
            </Button>
          </>
        )}
        {step === 3 && (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              Reset Password
            </Typography>
            <Typography variant="body2" align="center" gutterBottom>
              Enter your new password below to reset it.
            </Typography>
            <TextField
              variant="outlined"
              label="New Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyPress}
              required
              fullWidth
              sx={styles.textField}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleResetPassword}
              fullWidth
              sx={styles.button}
            >
              Reset Password
            </Button>
          </>
        )}
        <Button
          variant="text"
          color="secondary"
          onClick={() => navigate('/LOgionnew')}
          fullWidth
          sx={styles.button}
        >
          Back to Login
        </Button>
      </Paper>
    </Box>
  );
};

const styles = {
  card: {
    padding: '20px',
    borderRadius: '8px',
    width: '400px',
    position: 'relative',
  },
  textField: {
    marginBottom: '15px',
  },
  button: {
    marginTop: '20px',
  },
};

export default Loginforgot;
