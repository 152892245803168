import React from 'react'
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import { StaffTableComponents } from '../../components/StaffTableComponents';
import { IoEyeSharp } from 'react-icons/io5';

const Paymenttable = () => {
    
    let header = [
        { label: "Id", field: "id" },
        { label: "Receipt", field: "receipt" },
        { label: "Full Name", field: "full_name" },
        {label:'Order Id',field:"order_id"},
        // 
        { label: "Payment Id", field: "payment_id" },
        {label:'  Amount',field:"amount_in_rupees"},
        { label: "Status", field: "status" },
        {label:'Created',field:"created_time"},

        // { "label": "receipt", "field": "action","subaction1": <IoEyeSharp className="indexeye"/> }
      ];
      const sumofkeys = [{ first: "staffname" }];
    
      return (
        <>
           <Sidebar />
        <div className="containerNormal mainContainerlarge">
            <Navbar/>
          
            <div>
        <StaffTableComponents
          head={header}
          title={"Payments"}
          axiostabledata={"myorder"}
        Add={""}
        Create={""}
        Edit={""}
        View={""}
          Sumofkeys={sumofkeys}
        />
        </div>
        </div>
        </>
    
      );
    }

export default Paymenttable

 