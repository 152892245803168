import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import img1 from './3.png'
import { baseurlvmkp } from './Baseurl';
import { Fortesresult, Roamin, tokenexistchecker } from './Workinglogic';
import Swal from 'sweetalert2';

const Health = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const labels = [
        { label: 'Weight', field: 'weight' },
        { label: 'Fitness', field: 'fitness' },
        { label: 'Vitality', field: 'vitality' },
        { label: 'Power/Strength', field: 'power' },
        { label: 'Sickness/Diseases', field: 'diseases' },
        { label: 'Nutrition', field: 'nutrition' },
        { label: 'Diet', field: 'diet' },
        { label: 'Cooking', field: 'cooking' },
        { label: 'Personal Hygiene', field: 'hygiene' },
        { label: 'Personal Care', field: 'personalCare' },
        { label: 'Sports', field: 'sports' },
        { label: 'Entertainment', field: 'entertainment' },
        { label: 'Emotions', field: 'emotion' },
        { label: 'Traumas', field: 'traumas' },
        { label: 'Stress', field: 'stress' },
        { label: 'Vacation', field: 'vacation' },
        { label: 'Lifestyle in Health', field: 'lifestyleInHealth' },
  
        { label: 'Goals in Health', field: 'goalsInHealth' },
      
       
     
      
      
     
 
    
   
    ];
    const initializeFields = () => {
     
     
         
        
 
 
         const initialFields = Array(18).fill().map((_, index) => {
             const config =  { selected: Array(4).fill(""), current: 0 };
             return { current: config.current, selected: config.selected };
         });
     
         return initialFields;
     };



     const [data1, setData1] = useState([])

    const [fieldsVisibility, setFieldsVisibility] = useState('');
    const [showErrors, setShowErrors] = useState(false);
    
    const healthTopics = [
          'cooking', 'diet', 'diseases', 'emotion', 'entertainment',
        'fitness', 'goalsInHealth', 'hygiene', 'lifestyleInHealth',
        'nutrition', 'personalCare', 'power', 'sports', 'stress',
        'traumas', 'vacation', 'vitality', 'cooking'
    ];
    

    const handleChange = (rowIndex, selectIndex, value) => {
        setFieldsVisibility(prev => {
            const updatedVisibility = prev.map((row, index) => {
                if (index === rowIndex) {
                    const newSelected = [...row.selected];
                    newSelected[selectIndex] = value;

                    const nextField = (value !== "Not Applicable" && value !== 'Select' && value !== 'Working') 
                        ? Math.min(row.current + 1, 3) 
                        : selectIndex;

                    return { current: nextField, selected: newSelected };  
                }
                return row;  
            });
            return updatedVisibility;  
        });
    };

    useEffect(() => {




    
    }, [ ])

    const handle = () => {
        const allRowsFilled = fieldsVisibility.map((row, index1) => 
 
            fieldsVisibility.filter((item,index) => index == index1).map((item)=>item.selected)[0].filter((item,index3)=>index3==row.current).every((item)=>item.length!==0)
    
    
            );
            console.log(allRowsFilled.filter((item)=>item==false).length)
           
        console.log(fieldsVisibility)
        setShowErrors(!(allRowsFilled.filter((item)=>item==false).length==0?true:false));   

    
         if (allRowsFilled.filter((item)=>item==false).length==0) {
            const result = {
                name: "health", exam:[{
                    "id":Number(id)
                    }],
                ...Object.fromEntries(
                    labels.map((label, index) => {
                        const selectedValues = fieldsVisibility[index].selected;

                        const notApplicableIndex = selectedValues.indexOf('Not Applicable');
                        const workingIndex = selectedValues.indexOf('Working');

                        const updatedAnswers = selectedValues.map((val, i) => {
                            if ((i > notApplicableIndex && notApplicableIndex !== -1) || (i > workingIndex && workingIndex !== -1)) {
                                return "";
                            }
                            return val;
                        });

                        const applicableAnswer = updatedAnswers[0] || "";
                        const workingAnswer = updatedAnswers[1] || "";
                        const criticalAnswer = updatedAnswers[2] || "";
                        const futureAnswer = updatedAnswers[3] || "";

                        return [
                            label.field, {
                                name: label.field,
                                healthQuestion: [
                                    { text: "Is this applicable?", healthQuestionOption: [{ text: "Applicable" }, { text: "Not Applicable" }], healthAnswer: { text: applicableAnswer },   area: label.label },
                                    { text: "Is this working?", healthQuestionOption: [{ text: "Working" }, { text: "Not Working" }], healthAnswer: { text: workingAnswer },   area: label.label },
                                    { text: "How Critical Is This?", healthQuestionOption: [{ text: "Not Critical" }, { text: "Somewhat Critical" }, { text: "Critical" }, { text: "Very Critical" }, { text: "Most Critical" }], healthAnswer: { text: criticalAnswer },  area: label.label },
                                    { text: "How do you see this in the future?", healthQuestionOption: [{ text: "Necessity" }, { text: "Wish/Want/Desire" }, { text: "Dream" }, { text: "Hope" }, { text: "Miracle" }], healthAnswer: { text: futureAnswer },   area: label.label }
                                ]
                            }
                        ];
                    })
                )
            };

            console.log(result);
            setIsLoading(true);
            axios.post(`${baseurlvmkp}/health/post`, result, {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            }).then(response => {
                console.log(response.status, response.data.token);
                // Uncomment for navigation
                navigate('/we');
            }).finally(() => {
                setIsLoading(false); 
              });
        } else {
            alert("Please fill out all fields for each row.");
        }
    };

    const [isScrolled, setIsScrolled] = useState(false);
    const [id,setId]=useState("")

    useEffect(() => {
        Fortesresult(navigate);
 
 tokenexistchecker(navigate);
   
        const user = JSON.parse(localStorage.getItem('user'));

        if (user && user.details && user.details.id) {

        axios.get(`${baseurlvmkp}/exam/getexampending/${user.details.id}` , {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        }).then((response) => {
            setId(response.data.id)

            axios.get(`${baseurlvmkp}/health/get/${response.data.healthid}` , {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            }).then((response) => {
                // console.log(response.data.map((item)=>item.questions));
               
                  
                 const cleanedData = Object.fromEntries(
                    Object.entries(response.data).map(([key, { healthQuestion, name }]) => [key, { healthQuestion, name }])
                );
                console.log(cleanedData)
                
                const cleanedData1 = Object.fromEntries(
                    Object.entries(cleanedData).map(([key, { healthQuestion  }]) => [key, { healthQuestion  }])
                );
               console.log(cleanedData1)
                  
               const allQuestionsWithKeys = Object.entries(cleanedData1).flatMap(([key, value]) => 
                Array.isArray(value.healthQuestion) ? 
                value.healthQuestion.map(question => ({ area: key, ...question })) : []
              );
              console.log(allQuestionsWithKeys)
    
              const mappedResults = allQuestionsWithKeys.map(question => {
                const { area, healthAnswer } = question;
                const answerText = healthAnswer.text;
            
                return {
                    area: area,
                    Applicable: answerText === 'Not Applicable' || answerText === 'Applicable' ? answerText : "",
                    Working: answerText === 'Not Working' || answerText === 'Working' ? answerText : "",
                    Critical: ['Not Critical', 'Somewhat Critical', 'Critical', 'Very Critical', 'Most Critical'].includes(answerText) ? answerText : "",
                    Future: answerText === 'Necessity' || answerText === 'Wish/Want/Desire' || answerText === 'Dream' || 
                            answerText === 'Hope' || answerText === 'Miracle' ? answerText : ""
                };
            });
            
            const uniqueData = mappedResults.reduce((acc, item) => {
                const existing = acc.find(entry => entry.area === item.area);
                
                if (existing) {
                  existing.Applicable = existing.Applicable || item.Applicable || '';
                  existing.Working = existing.Working || item.Working || '';
                  existing.Critical = existing.Critical || item.Critical || '';
                  existing.Future = existing.Future || item.Future || '';
                } else {
                  acc.push({
                    area: item.area,
                    Applicable: item.Applicable || '',
                    Working: item.Working || '',
                    Critical: item.Critical || '',
                    Future: item.Future || '',
                  });
                }
                
                return acc;
              }, []);
              
              // Step 2: Map to the desired structure
              const data = uniqueData.map(question => {
                const applicable = question.Applicable || '';
                const working = question.Working || '';
                const critical = question.Critical || '';
                const future = question.Future || '';
              
                // Create an array for selected values based on conditions
                const selectedArray = [
                  applicable  ,
                  working ,
                  critical  ,
                  future 
                ];
              
                // Count how many selected values are not default
                const currentCount = selectedArray.filter(value => value.length !==0 &&  value.length !==0 &&  value.length !==0 &&  value.length !==0).length-1;
              
                return {
                  selected: selectedArray,
                  current: currentCount // Store the count of filled conditions
                };
              });
          
              setData1(data)
              setFieldsVisibility(data.length === 0 ? initializeFields() : data);
    console.log(data)
            }).catch((error)=>{
    console.log(error)
    setFieldsVisibility(initializeFields())
            })

        }).catch((error)=>{
            console.log(error)
            setFieldsVisibility(initializeFields())
                     })
                    }

        const handleScroll = () => {
            setIsScrolled(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        window.scrollTo(0, 0);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
       localStorage.removeItem('user');
        navigate('/');  
     };
     const handleClick = () => {
        Swal.fire({
            title: 'Customer Support',
            html: `
              <p><strong>WhatsApp:</strong> <a href="https://wa.me/919619604038" target="_blank">+91 9619604038</a></p>
              <p><strong>Email:</strong> <a href="https://mail.google.com/mail/?view=cm&fs=1&to=reachus@victormanickam.com" target="_blank">reachus@victormanickam.com</a></p>
            `,
            icon: 'info',
            confirmButtonText: 'Close',
          });
      };
    return (
        <div className='testhealth'>
        <br></br>
        {/* <br></br> */}
        <p className="newtech" onClick={handleClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="43" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
          <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5"/>
        </svg>
        <span>Help</span>
      </p>
        <div className='maxcenter '  >
        <div className='rs11' style={{display:'flex',background:'white',justifyContent:'center',flexDirection:'row',gap:50}}>
        <h1 className='black fontsize'>Relationships</h1>
        <hr/>
        <h1 className='black fontsize' style={{color:'#F8C346'}}>Health</h1>
        <hr/>
        <h1 className='black fontsize'>Work and Education</h1>
         
         <hr/>
         <h1 className='black fontsize'>Wealth</h1>
        <hr/>
        <h1 className='black fontsize'>Self Awareness</h1>

        </div>
      
         </div>
         <br></br>{fieldsVisibility.length > 0 &&<>
            <div className={`display ${isScrolled ? 'blockdisplay' : 'displaynone'}`}>
                <p className='black jhk'>
                    <div className='middle'>
                        <div className='cont'>
                            <div>
                                <div className='row tesfier'>
                                    <div className='col black'></div>
                                    <div className='col black question' style={{color:'rgb(255, 189, 22)'}}>Is this area applicable for you?</div>
                                    <div className='col black question' style={{color:'rgb(255, 189, 22)'}}>Is this area working for you?</div>
                                    <div className='col black question' style={{color:'rgb(255, 189, 22)'}}>How critical is this area for you?</div>
                                    <div className='col black question' style={{color:'rgb(255, 189, 22)'}}>How do you see your near future in this area?</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p>
            </div>
            <div className="text-end mb-3  logoutpostion">
            <span className="badge newyellow p-2" onClick={handleLogout}>
              <span className='logout12'>Logout</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out">
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/>
                <polyline points="16 17 21 12 16 7"/>
                <line x1="21" y1="12" x2="9" y2="12"/>
              </svg>
            </span>
          </div>
            <div className='middle'>
                
                <div className='cont'>
                <img src={img1} className='imglogo' style={{width:'50px'}} alt="Description of the image" />

                    <h1 className='black   tyttyt' style={{ color: '#F8C346' }}>Health</h1>
                    <p className='black   Personal'>This part of the Personal Assessment Test is created for you to reflect on your Health.</p>

                    <br />
                    <div className={`display ${isScrolled ? 'displaynone' : 'blockdisplay'}`}>
                        <div className='row'>
                            <div className='col black'></div>
                            <div className='col black question'style={{color:'rgb(255, 189, 22)'}}>Is this area applicable for you?</div>
                            <div className='col black question'style={{color:'rgb(255, 189, 22)'}}>Is this area working for you?</div>
                            <div className='col black question'style={{color:'rgb(255, 189, 22)'}}>How critical is this area for you?</div>
                            <div className='col black question'style={{color:'rgb(255, 189, 22)'}}>How do you see your near future in this area?</div>
                        </div>
                    </div>
{/*                    
                    {labels.map((label, index) => (
    <div className='row pading12' key={index}>
        <div className='col black cap fontweight500'>{label}</div>
        <div className='col black'>
            {fieldsVisibility[index].current >= 0 && (
                <select
                    className='form-select hh'
                    value={fieldsVisibility[index].selected[0]}
                    onChange={(e) => handleChange(index, 0, e.target.value)}
                    style={{ borderColor: showErrors && fieldsVisibility[index].selected[0] === "" ? 'red' : 'black' }}
                >
                    <option disabled value="">--Select--</option>
                    <option>Not Applicable</option>
                    <option>Applicable</option>
                </select>
            )}
        </div>
        <div className='col black'>
            {fieldsVisibility[index].current >= 1 && (
                <select
                    className='form-select hh'
                    value={fieldsVisibility[index].selected[1]}
                    onChange={(e) => handleChange(index, 1, e.target.value)}
                    style={{ borderColor: showErrors && fieldsVisibility[index].selected[1] === "" ? 'red' : 'black' }}
                >
                    <option disabled value="">--Select--</option>
                    <option>Not Working</option>
                    <option value="Working">Working</option>
                </select>
            )}
        </div>
        <div className='col black'>
            {fieldsVisibility[index].current >= 2 && (
                <select
                    className='form-select hh'
                    value={fieldsVisibility[index].selected[2]}
                    onChange={(e) => handleChange(index, 2, e.target.value)}
                    style={{ borderColor: showErrors && fieldsVisibility[index].selected[2] === "" ? 'red' : 'black' }}
                >
                    <option disabled value="">--Select--</option>
                    <option>Not Critical</option>
                    <option>Somewhat Critical</option>
                    <option>Critical</option>
                    <option>Very Critical</option>
                    <option>Most Critical</option>
                </select>
            )}
        </div>
        <div className='col black'>
            {fieldsVisibility[index].current >= 3 && (
                <select
                    className='form-select hh'
                    value={fieldsVisibility[index].selected[3]}
                    onChange={(e) => handleChange(index, 3, e.target.value)}
                    style={{ borderColor: showErrors && fieldsVisibility[index].selected[3] === "" ? 'red' : 'black' }}
                >
                    <option disabled value="">--Select--</option>
                    <option>Necessity</option>
                    <option>Wish/Want/Desire</option>
                    <option>Dream</option>
                    <option>Hope</option>
                    <option>Miracle</option>
                </select>
            )}
        </div>
    </div>
))} */}


{fieldsVisibility.length > 0 ? labels.map((label, index) => (
                        
                        <div className='row pading12' key={index}>
                            <div className='col cap black fontweight500 d-flex justify-content-center align-items-center' style={{    color: '#ffbd16'}} >{label.label}&nbsp;</div>
                            
                            <div className='col black'>
                                {fieldsVisibility[index].current >= 0 && (
                                     <select
                                     className='form-select rs'
                                     value={fieldsVisibility[index].selected[0]}
                                     onChange={(e) => handleChange(index, 0, e.target.value)}
                                     style={{
                                        border: '3px solid #ffbd165c',                     
                                        borderColor: showErrors && fieldsVisibility[index].selected[0] === "" ? '#ff12005c' : '#ffbd165c'   }}                                 >
                                     <option disabled value="">--Select--</option>
                                     <option>Not Applicable</option>
                                     <option>Applicable</option>
                                 </select>
                                )}
                            </div>
                            
                            <div className='col black'>
                                {fieldsVisibility[index].current >= 1 && (
                                    <select
                                        className='form-select rs'
                                        onChange={(e) => handleChange(index, 1, e.target.value)}
                                        value={fieldsVisibility[index].selected[1] || ""}
                                        style={{
                                            border: '3px solid #ffbd165c',                     
                                            borderColor: showErrors && fieldsVisibility[index].selected[1] === "" ? '#ff12005c' : '#ffbd165c'   }}                                    >
                                        <option disabled value="">--Select--</option>
                                        <option>Not Working</option>
                                        <option value="Working">Working</option>
                                    </select>
                                )}
                            </div>
                            
                            <div className='col black'>
                                {fieldsVisibility[index].current >= 2 && (
                                    <select
                                        className='form-select rs'
                                        onChange={(e) => handleChange(index, 2, e.target.value)}
                                        value={fieldsVisibility[index].selected[2] || ""}
                                        style={{
                                            border: '3px solid #ffbd165c',                     
                                            borderColor: showErrors && fieldsVisibility[index].selected[2] === "" ? '#ff12005c' : '#ffbd165c'   }}                                    >
                                        <option disabled value="">--Select--</option>
                                        <option>Not Critical</option>
                                        <option>Somewhat Critical</option>
                                        <option>Critical</option>
                                        <option>Very Critical</option>
                                        <option>Most Critical</option>
                                    </select>
                                )}
                            </div>
                            
                            <div className='col black'>
                                {fieldsVisibility[index].current >= 3 && (
                                    <select
                                        className='form-select rs '
                                        onChange={(e) => handleChange(index, 3, e.target.value)}
                                        value={fieldsVisibility[index].selected[3] || ""}
                                        style={{
                                            border: '3px solid #ffbd165c',                     
                                            borderColor: showErrors && fieldsVisibility[index].selected[3] === "" ? '#ff12005c' : '#ffbd165c'   }}                                    >
                                        <option disabled value="">--Select--</option>
                                        <option>Necessity</option>
                                        <option>Wish/Want/Desire</option>
                                        <option>Dream</option>
                                        <option>Hope</option>
                                        <option>Miracle</option>
                                    </select>
                                )}
                            </div>
                        </div>
                    )):""}
                    <br />
                    <div className='row fotbuttons'>
                    <button className='btn btn-primary bigger' onClick={()=>{navigate('/exam')}}>Back</button>
                    {isLoading?  <button className='btn btn-primary bigger' style={{background:'white'}}  > 
                            <svg viewBox="25 25 50 50" class="spinner-container" style={{width:'21px'}}>
  <circle cx="50" cy="50" r="20" class="spinner-loader"></circle>
</svg>

                            </button>:<button className='btn btn-primary bigger'  onClick={handle}> 
                      Next

                            </button>}
                            {/* <button className='btn btn-primary bigger' style={{background:'rgb(255, 189, 22)'}} onClick={handle}>Next</button> */}
                       
                    </div>
                    <br></br>
                    <div className='row'>
                        <div className='col'>
                        </div>
                    </div>
                </div>
            </div>
 </> } 
  </div>
    );
};

export default Health;
